import { Alert, AlertTitle, LinearProgress, Stack } from "@mui/material";
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "../../../../modules/generic/components/crud-data-grid/types";
import { FilteredMomentInfo } from "../../../../modules/moment-filter/hooks/utils";
import {
  defaultSortOptionMap,
  useMomentSort,
} from "../../../../modules/moment/moment-sort/hooks/use-moment-sort";
import { MomentCardMomentActivityStats } from "../../../../modules/moment/server/supabase-moment-service";
import { useMomentHistoryGridColumns } from "../hooks/use-moments-history-grid-columns";

export const MomentsHistoryDataGrid: FC<{
  data: FilteredMomentInfo<MomentCardMomentActivityStats>;
}> = ({ data }) => {
  const { error, loading, moments } = data;

  const { t } = useTranslation();

  const sortMoments = useMomentSort();
  const cols = useMomentHistoryGridColumns();

  if (error)
    return (
      <Alert severity="error">
        <AlertTitle>{t("Something went wrong")}</AlertTitle>
      </Alert>
    );

  if (loading) return <LinearProgress />;

  if (!moments || moments.length === 0) {
    return <Alert severity="info">{t("No moments found")}</Alert>;
  }

  return (
    <DataGridPro<Row<MomentCardMomentActivityStats>>
      columns={Object.entries(cols).map(([field, v]) => ({
        field,
        sortable: false,
        ...v,
      }))}
      rows={sortMoments(moments, {
        ...defaultSortOptionMap,
        schedule: { ...defaultSortOptionMap.schedule, order: "desc" },
      }).map((v) => ({ isNew: false, ...v }))}
      hideFooterSelectedRowCount
      disableColumnMenu
      pagination
      slots={{ toolbar: Export }}
    />
  );
};

const Export: FC = () => {
  return (
    <Stack direction="row" justifyContent="flex-end" width="100%" py={1}>
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    </Stack>
  );
};
