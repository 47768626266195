import {
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  FormControlProps,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Accept, useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Duplicate } from "../../../../styles/icons/duplicate";
import { X } from "../../../../styles/icons/x";
import { getAspectRatio } from "../../../../utils/aspect-ratio";
import { bytesToSize } from "../../../../utils/bytes-to-size";
import { ElementTracking, useElementTracking } from "../../../analytics/analytics";
import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";

type State = {
  uploading: boolean;
  imageUrl: string | null;
  preview: string | null;
  file?: File;
};

type TextConfig = {
  add?: string;
  remove: string;
  uploadError: string;
};

export interface FilePickerProps {
  value: string | null;
  handleUpload: (file: File) => Promise<File | undefined>;
  accept: Accept;
  text: TextConfig;
  content?: ReactNode;
  disabled?: boolean;
  transform?: (file: File) => Promise<File>;
  handleRemove?: () => void;
  preview?: boolean;
  onClick?: (src?: string) => void;
  formControlProps?: FormControlProps;
  selected?: boolean;
  name?: string;
}

export const FilePicker: FC<FilePickerProps & ElementTracking> = ({
  value,
  handleUpload,
  disabled,
  accept,
  text,
  content,
  transform,
  handleRemove,
  preview = true,
  onClick,
  formControlProps,
  selected = false,
  analyticsCategory,
  name,
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const analyticsProps = useElementTracking(analyticsCategory, name, "file-picker");
  const [state, setState] = useState<State>({
    imageUrl: value,
    uploading: false,
    preview: value,
  });

  const [previewAspect, setPreviewAspect] = useState<null | number>(null);
  const [openImagePreview, setOpenImagePreview] = useState<boolean>(false);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles: 1,
    onDrop: (files: File[]): void => {
      if (files.length === 1) {
        const transformer = transform || (() => Promise.resolve(files[0]));
        void transformer(files[0])
          .then((file) => {
            setState((prev) => ({
              ...prev,
              file,
              ...(file.type.startsWith("image/")
                ? { preview: URL.createObjectURL(file) }
                : { preview: "pdf" }),
            }));
          })
          .catch(console.error);
      } else {
        toast.error(text.uploadError);
      }
    },
  });

  useEffect(() => {
    const setAspect = async (): Promise<void> => {
      if (!state.preview) return setPreviewAspect(null);
      // Will update this when we have a better way to handle PDF previews,
      // for now we just want it to be truthy to show the IconWithChip
      if (state.preview === "pdf") return setPreviewAspect(1);

      setPreviewAspect(await getAspectRatio(state.preview));
    };
    void setAspect().catch(console.error);
  }, [state.preview]);

  useEffect(() => {
    void (async () => {
      if (!state.file) return;

      setState((prev) => ({ ...prev, uploading: true }));
      const file = await handleUpload(state.file);
      setState((prev) => ({
        ...prev,
        uploading: false,
        file,
        preview: file ? prev.preview : null,
      }));
    })().catch(console.error);
  }, [state.file, t, handleUpload, handleRemove]);

  const handleClick = (): void => {
    if (preview) {
      setOpenImagePreview(true);
    } else {
      state.preview && onClick?.(state.preview);
    }
  };

  return (
    <FormControl variant="standard" sx={{ my: 1 }} fullWidth {...formControlProps}>
      {!state.preview && !disabled && (
        <Box
          sx={{
            alignItems: "center",
            border: 1,
            borderRadius: 1,
            borderStyle: "dashed",
            borderColor: "divider",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            outline: "none",
            px: 3,
            my: 1,
            ...(isDragActive && {
              backgroundColor: "action.active",
              opacity: 0.5,
            }),
            "&:hover": {
              backgroundColor: "action.hover",
              cursor: "pointer",
              opacity: 0.5,
            },
            width: "100%",
            minWidth: "400px",
          }}
          data-testid="file-dropzone"
          {...getRootProps()}
        >
          <input {...analyticsProps} {...getInputProps()} disabled={disabled} />
          {content ? (
            content
          ) : (
            <>
              <Box
                sx={{
                  "& img": {
                    width: 100,
                  },
                }}
              >
                {<Avatar sx={{ bgcolor: "success.main" }}>+</Avatar>}
              </Box>
              <Box sx={{ p: 2 }}>
                <Typography variant="button">{text.add}</Typography>
              </Box>
            </>
          )}
        </Box>
      )}

      {previewAspect && (
        <Box ref={ref} minWidth="400px">
          <List sx={{ width: "100%", p: 0 }}>
            <ListItem
              key={state.file?.name ?? ""}
              sx={{
                border: 2,
                borderColor: "divider",
                borderRadius: 1,
                "& + &": {
                  mt: 1,
                },
                paddingX: 1,
                width: "100%",
                display: "block",
                ...(selected && {
                  border: 2,
                  borderStyle: "solid",
                  borderColor: "primary.main",
                }),
              }}
            >
              <ImagePreview state={state} onClick={handleClick} />
              <Tooltip title={text.remove}>
                <IconButton
                  data-analytics-id="file-picker-dialog-remove"
                  sx={{ position: "absolute", top: 0, right: 0 }}
                  disabled={disabled}
                  onClick={() => {
                    setPreviewAspect(null);
                    setState((prev) => ({ ...prev, file: undefined, preview: null }));
                    handleRemove?.();
                  }}
                >
                  <X fontSize="small" />
                </IconButton>
              </Tooltip>
            </ListItem>
          </List>

          {preview && (
            <Popover
              anchorEl={ref.current}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              open={openImagePreview}
              onClose={() => setOpenImagePreview(false)}
            >
              {state.preview && <img src={state.preview} alt="preview" width={500} />}
            </Popover>
          )}
        </Box>
      )}
    </FormControl>
  );
};

const IconWithChip: FC = () => {
  return (
    <Box sx={{ position: "relative" }}>
      <DescriptionOutlined sx={{ fontSize: 120 }} />
      <Chip
        label="PDF"
        size="small"
        color="primary"
        sx={{
          position: "absolute",
          bottom: 5,
          left: 5,
          fontSize: "0.7rem",
          backgroundColor: "primary.main",
        }}
      />
    </Box>
  );
};

const ImagePreview: FC<{ state: State; onClick: () => void }> = ({ state, onClick }) => {
  if (state.uploading) {
    return (
      <Box display="flex">
        <ListItemIcon>
          <Button data-analytics-id="file-picker-dialog-loading" loading={true} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Button data-analytics-id="file-picker-dialog-uploading" disabled={true}>
              Uploading...
            </Button>
          }
          primaryTypographyProps={{
            color: "textPrimary",
            variant: "subtitle2",
          }}
        />
      </Box>
    );
  }

  const fileType = state.file?.type;

  let previewComponent;
  if (fileType === "application/pdf") {
    previewComponent = <IconWithChip />;
  } else {
    previewComponent = (
      <IconButton
        data-analytics-id="file-picker-dialog-preview"
        onClick={onClick}
        sx={{ width: "100%" }}
      >
        <img src={state.preview || ""} alt="preview" style={{ width: "100%" }} />
      </IconButton>
    );
  }

  return (
    <Stack display="flex" alignItems="center" justifyContent="center">
      <ListItemIcon>
        {state.preview ? previewComponent : <Duplicate fontSize="small" />}
      </ListItemIcon>
      {state.file?.size && (
        <ListItemText
          secondary={
            <Box component={"span"}>{state.file.size && bytesToSize(state.file.size)}</Box>
          }
        />
      )}
    </Stack>
  );
};
