import { useAuth0 } from "@auth0/auth0-react";
import { DependencyList, useEffect, useRef } from "react";
import { useToken } from "../../auth/use-token";
import { Database } from "../types/database-definitions";
import { OrgUser, SupabaseHandlerArg, SupabaseSchemaName } from "./types";
import { withSupabaseToken } from "./utils";

/**
 * An Effect for loading data from Supabase; may redirect if not authenticated.
 */
export function useSupabase<SchemaName extends SupabaseSchemaName<Database> = "public">(
  callback: (arg0: SupabaseHandlerArg<SchemaName>) => Promise<void>,
  deps: DependencyList,
  schema: SchemaName = "public" as SchemaName
): void {
  const { user, isLoading, loginWithRedirect } = useAuth0<OrgUser>();
  const getToken = useToken();
  const abortControllerRef = useRef<AbortController | null>(null); 

  useEffect(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    void withSupabaseToken<SchemaName>(user, isLoading, getToken, loginWithRedirect, schema, abortControllerRef)
      .then((ctx) => ctx && callback(ctx))
      .catch(console.error);
    return () => {
      abortController?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoading, loginWithRedirect, getToken, schema, ...deps]);
}
