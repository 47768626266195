import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash-es";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSegmentValueFieldCsvHelper } from "../../hooks/use-segment-value-field-csv-helper";
import { useSegmentFieldValueOptions } from "../../hooks/use-segment-value-options";
import { UsableAccountPersonField } from "../../server/supabase-segment-field-service";
import { SegmentCondition } from "../../types";
import { RenderInput } from "./segment-value-field-autocomplete-input";

export const AutocompleteValueSelect: FC<{
  condition: SegmentCondition;
  segmentField?: UsableAccountPersonField;
  handleFieldChange: (value: string[]) => void;
  error?: string;
  disabled?: boolean;
  hidden?: boolean;
  recipientCondition?: SegmentCondition;
}> = ({
  condition,
  handleFieldChange,
  error,
  disabled,
  hidden,
  segmentField,
  recipientCondition,
}) => {
  const { data, loading } = useSegmentFieldValueOptions({
    audience: recipientCondition?.field,
    segmentField,
    disabled,
  });

  const selectedFieldOptions = data || [];

  const {
    parsing,
    invalidEmailsDialog,
    handleEntries,
    exportCsvData,
    dropzoneState: { getRootProps, getInputProps, isDragActive, open: openFileUpload },
  } = useSegmentValueFieldCsvHelper({
    options: condition.values,
    allOptions: selectedFieldOptions,
    disabled: !!disabled,
    setOptions: handleFieldChange,
  });

  const isDisabled = disabled || loading || selectedFieldOptions.length === 0;

  // If the options are disabled then the loader will always return an empty array
  // which will show as an error (as the selected values are not in the options)
  const displayErrors = !disabled;

  return (
    <div {...getRootProps()}>
      <Autocomplete
        multiple
        size="small"
        limitTags={isDisabled ? undefined : 3}
        id={`value-${condition.id}`}
        options={selectedFieldOptions}
        hidden={hidden}
        disabled={isDisabled}
        fullWidth
        value={condition.values ? condition.values : []}
        data-testid="value-autocomplete"
        onChange={(_, value) => {
          handleFieldChange(value ? value.slice() : []);
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const rawTagProps = getTagProps({ index });
            // Remove onDelete from the tagProps if it's disabled
            const { onDelete, ...tagProps } = rawTagProps;

            return (
              <Chip
                {...tagProps}
                onDelete={isDisabled ? undefined : onDelete}
                color={
                  displayErrors && !selectedFieldOptions.includes(option) ? "error" : "default"
                }
                key={option}
                label={option}
                size="small"
              />
            );
          })
        }
        renderInput={(params) => (
          <RenderInput
            params={params}
            isDragActive={isDragActive}
            openFileUpload={openFileUpload}
            exportCsvData={exportCsvData}
            loading={loading}
            parsing={parsing}
            condition={condition}
            handleEntries={handleEntries}
            error={error}
            disabled={disabled}
            segmentField={segmentField}
            getInputProps={getInputProps}
          />
        )}
      />
      {invalidEmailsDialog.open && invalidEmailsDialog.data && (
        <InvalidEmailsDialog
          valid={invalidEmailsDialog.data.valid}
          invalid={invalidEmailsDialog.data.invalid}
          duplicates={invalidEmailsDialog.data.duplicates}
          error={invalidEmailsDialog.data.error}
          onClose={invalidEmailsDialog.handleClose}
          onSubmit={(valid) => {
            invalidEmailsDialog.handleClose();
            handleFieldChange(valid);
          }}
        />
      )}
    </div>
  );
};

const InvalidEmailsDialog: FC<{
  valid: string[];
  invalid: string[];
  duplicates: Record<string, string[]>;
  error?: string;
  onClose: () => void;
  onSubmit: (valid: string[]) => void;
}> = ({ valid, invalid, duplicates, error, onClose, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{t("There was a problem uploading your data")}</DialogTitle>
      <DialogContent>
        {error && <Typography>{error}</Typography>}
        {invalid.length > 0 && (
          <>
            <Typography variant="subtitle1">{t("The following entries are invalid")}</Typography>
            <List>
              {invalid.map((entry, i) => (
                <ListItem key={i}>{entry}</ListItem>
              ))}
            </List>
          </>
        )}
        {!isEmpty(duplicates) && (
          <>
            <Typography variant="subtitle1">{t("The following entries are duplicated")}</Typography>
            <List>
              {Object.entries(duplicates).map(([key, values], i) => (
                <ListItem key={i}>
                  {key}: {values.join(", ")}
                </ListItem>
              ))}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          data-analytics-id="segment-form-upload-error-dialog-cancel"
          onClick={onClose}
          color="warning"
        >
          {t("Cancel")}
        </Button>
        {!error && (
          <Button
            data-analytics-id="segment-form-upload-error-dialog-continue"
            onClick={() => onSubmit(valid)}
            color="info"
          >
            {t("Continue")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
