/* eslint-disable @typescript-eslint/no-explicit-any */
import FilterAlt from "@mui/icons-material/FilterAlt";
import { Alert, Autocomplete, InputAdornment, TextField, Typography } from "@mui/material";
import {
  DataGridPro,
  GridSlotsComponent,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarProps,
} from "@mui/x-data-grid-pro";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { propertyNotEmpty } from "../../../utils/not-empty";
import { UUID } from "../../../utils/uuid";
import {
  SegmentWithMoments,
  SupabaseSegment,
  SupabaseSegmentService,
} from "../../segment/server/supabase-segment-service";
import { EmployeeData } from "../hooks/use-employee-data";

export const EmployeeDataGrid: FC<{
  employeeData: EmployeeData;
  type: "hris" | "csv";
  audiences: SupabaseSegment[];
  defaultAudience: UUID | null;
}> = ({ employeeData, type, audiences, defaultAudience }) => {
  const [rows, setRows] = useState<EmployeeData["data"] | "LOADING">("LOADING");

  const columns = useMemo(
    () =>
      employeeData
        ? [
            ...(employeeData.keys.includes("first_name")
              ? [{ field: "first_name", headerName: "first_name", valueFormatter }]
              : []),
            ...(employeeData.keys.includes("last_name")
              ? [{ field: "last_name", headerName: "last_name", valueFormatter }]
              : []),
            ...employeeData.keys
              .filter((x) => !["first_name", "last_name", "id"].includes(x))
              .map((x) => ({ field: x, headerName: x, valueFormatter })),
          ]
        : [],
    [employeeData],
  );

  const onAudienceChange = useMemo(() => (x: EmployeeData["data"] | "LOADING") => setRows(x), []);

  return (
    <DataGridPro
      autoHeight
      loading={rows === "LOADING"}
      columns={columns}
      rows={rows === "LOADING" ? [] : rows}
      pagination={true}
      slots={{ toolbar: ToolbarAutocomplete as GridSlotsComponent["toolbar"] }}
      slotProps={{
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        toolbar: {
          allColumns: true,
          type,
          defaultAudience,
          audiences,
          employeeData,
          onAudienceChange,
        } as any,
      }}
    />
  );
};

function valueFormatter<T>(value: T): T | string {
  return value ?? "";
}

const ToolbarAutocomplete: FC<
  GridToolbarProps & {
    type: "csv" | "hris";
    defaultAudience: UUID | null;
    audiences: SegmentWithMoments[];
    employeeData: EmployeeData;
    onAudienceChange: (x: EmployeeData["data"] | "LOADING") => void;
  }
> = ({ type, employeeData, audiences, defaultAudience, onAudienceChange }) => {
  const { t } = useTranslation();
  const [selectedAudience, setSelectedAudience] = useState<UUID | null>(defaultAudience);

  const { data: rows, loading } = useSupabaseLoader(
    async ({ supabase }) => {
      onAudienceChange("LOADING");

      if (!selectedAudience) return employeeData.data;

      const { data } = await new SupabaseSegmentService(supabase).getCount(selectedAudience, false);
      if (!data) return [];

      const allowed = data.filter(propertyNotEmpty("id")).map(({ id }) => id);

      return employeeData.data.filter((y) => y.id && allowed.includes(y.id));
    },
    [selectedAudience, employeeData, onAudienceChange],
  );

  const audience = useMemo(
    () => audiences.find((x) => x.id === selectedAudience),
    [audiences, selectedAudience],
  );

  useEffect(() => onAudienceChange(rows ?? []), [rows, onAudienceChange]);

  return (
    <>
      {type === "csv" && selectedAudience && (
        <Alert severity="warning">
          <Typography variant="body2">
            {t(
              "The exported CSV will only contain a subset of your records, please do not import it as filtered records will be deleted.",
            )}
          </Typography>
        </Alert>
      )}
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <GridToolbarExport />
        <Autocomplete
          value={audience}
          options={audiences ?? []}
          disabled={loading}
          loading={loading}
          onChange={(_, x) => setSelectedAudience(x?.id ?? null)}
          sx={{ minWidth: "250px" }}
          getOptionLabel={(option) => option.name ?? ""}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              id="audience-filter"
              placeholder={t("Audience")}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterAlt />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </GridToolbarContainer>
    </>
  );
};
