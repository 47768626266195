import { Box, Chip } from "@mui/material";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { multiformatAtom } from "../multiformat/store/multiformat-atom";

export const ContentTypeChips: FC<{
  contentTypes: string[];
  onDelete: (contentType: string) => void;
}> = ({ contentTypes, onDelete }) => {
  // TODO: remove in GRO-299
  const multiformat = useAtomValue(multiformatAtom);

  if (contentTypes.length === 0) return null;

  return (
    <Box>
      {contentTypes.map((t) => {
        const ceAppName = multiformat
          ? (multiformat["content-types"].find((c) => c["Content Type"] === t)?.[
              "CE App Name"
            ] ?? t)
          : undefined;
        return (
          <Chip
            onDelete={() => onDelete(t)}
            sx={{ mr: 1 }}
            label={ceAppName ?? "..."}
            variant="outlined"
            key={t}
          ></Chip>
        );
      })}
    </Box>
  );
};
