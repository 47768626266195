import { Box, Card, CardContent, InputLabel, LinearProgress } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../../modules/layout/components/page";
import { PageContent } from "../../../modules/layout/components/page-content";
import { PageHeader } from "../../../modules/layout/components/page-header";
import { PageHeading } from "../../../modules/layout/components/page-heading";
import { MomentFilter } from "../../../modules/moment-filter/components/moment-filter";
import { useClientFilteredMoments } from "../../../modules/moment-filter/hooks/use-client-filtered-moments";
import { FilteredMomentInfo } from "../../../modules/moment-filter/hooks/utils";
import { MomentSort } from "../../../modules/moment/moment-sort/moment-sort";
import {
  MomentCardMomentActivityStats,
  SupabaseMomentHistoryPageStatsService,
  SupabaseMomentsSentStatsService,
} from "../../../modules/moment/server/supabase-moment-service";
import { Search } from "../../../modules/search/search";
import { useFlags } from "../../../server/optimizely";
import { MomentsHistoryDataGrid } from "./components/moments-history-grid";

const MomentsHistory: FC = () => {
  const {
    flags: { moment_stats_v2 },
    flagsLoaded,
  } = useFlags("moment_stats_v2");

  if (!flagsLoaded) return <LinearProgress />;

  return moment_stats_v2 ? <MomentsHistoryV2 /> : <MomentsHistoryV1 />;
};

const MomentsHistoryV1: FC = () => {
  const data = useClientFilteredMoments((s, a) =>
    new SupabaseMomentsSentStatsService(s).getAll(a, { order: [{ column: "id" }] }),
  );

  return <MomentsHistoryInner data={data} />;
};

const MomentsHistoryV2: FC = () => {
  const data = useClientFilteredMoments((s, a) =>
    new SupabaseMomentHistoryPageStatsService(s).getAll(a, { order: [{ column: "id" }] }),
  );

  return <MomentsHistoryInner data={data} />;
};

const MomentsHistoryInner: FC<{
  data: FilteredMomentInfo<MomentCardMomentActivityStats>;
}> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Page title="Sent Moments | ChangeEngine">
      <PageHeader>
        <PageHeading heading={t("Sent Moments")} />
      </PageHeader>
      <PageContent>
        <Box display="flex">
          <Box display="flex" alignItems="center" flexGrow={1}>
            <InputLabel>{t("Sort by:")}</InputLabel>
            <MomentSort />
          </Box>
          <Box display="flex">
            <Search />
            <MomentFilter displayStatusOptions={false} displayEventOptions={false} />
          </Box>
        </Box>
        <Card>
          <CardContent>
            <MomentsHistoryDataGrid data={data} />
          </CardContent>
        </Card>
      </PageContent>
    </Page>
  );
};

export default MomentsHistory;
