import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Permissions, PersonDetails } from "../../server/supabase-person-service";
import { PermissionsFormType } from "../../types";

export const EmployeeSelect: FC<{
  control: Control<PermissionsFormType>;
  employees: PersonDetails[];
  existing?: Permissions;
}> = ({ control, employees, existing }) => {
  const { t } = useTranslation();

  return (
    <Controller
      name="person_ids"
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          multiple={true}
          disabled={!!existing}
          id="employee-select"
          value={employees.filter((s) => field.value?.includes(s.id))}
          options={employees}
          renderOption={(renderProps, option) => (
            <Box component="li" {...renderProps} key={option.id}>
              {makeLabel(option)}
            </Box>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip {...getTagProps({ index })} key={option.id} label={makeLabel(option)} />
            ))
          }
          renderInput={(params) => (
            <TextField
              label={t("Select Employee(s)")}
              variant="standard"
              {...params}
              helperText={error?.message}
              error={!!error}
              InputProps={{
                ...params.InputProps,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore https://github.com/mui/material-ui/issues/20160#issuecomment-600277849
                "data-testid": "employee-select",
              }}
            />
          )}
          getOptionLabel={(option) => makeLabel(option)}
          onChange={(_, v) => {
            field.onChange(v.map((x) => x.id));
          }}
        />
      )}
    />
  );
};

export const makeLabel = (person: PersonDetails): string => {
  return `${person.first_name} ${person.last_name} (${person.email})`;
};
