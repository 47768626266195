import { Link, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChannelIcon } from "../../../../modules/channels/components/channel-icon";
import { Columns } from "../../../../modules/generic/components/crud-data-grid/types";
import { MomentCardActions } from "../../../../modules/moment-card/components/moment-card-actions";
import { Program } from "../../../../modules/moment-card/types";
import { isChatChannel } from "../../../../modules/moment/component/channel/channel";
import { MomentCardMomentActivityStats } from "../../../../modules/moment/server/supabase-moment-service";
import { useFlags } from "../../../../server/optimizely";
import { formatDatetimeShort, fromISO8601 } from "../../../../utils/iso8601";

export function useMomentHistoryGridColumns(): Columns<MomentCardMomentActivityStats> {
  const {
    flags: { moment_stats_v2 },
  } = useFlags("moment_stats_v2");
  const { t } = useTranslation();

  return useMemo(
    () => ({
      title: {
        headerName: "Title",
        flex: 4,
        renderCell: ({ row: { title, id } }) => <Link href={`/moments/${id!}`}>{title}</Link>,
      },
      program: {
        headerName: "Program",
        flex: 3,
        valueFormatter: (value) => (value as Program)?.title ?? "-",
      },
      attime: {
        headerName: "When",
        flex: 2,
        valueFormatter: (value) => (value ? formatDatetimeShort(fromISO8601(value)) : "-"),
      },
      ...(moment_stats_v2
        ? {
            deliveries: {
              headerName: "Delivered",
              flex: 2,
            },
          }
        : {
            sends: {
              headerName: "Sent",
              flex: 2,
            },
          }),

      unique_clicks: {
        headerName: moment_stats_v2 ? "Link Clicks" : "Clicks",
        flex: 2,
      },
      opens: {
        headerName: "Opens",
        flex: 2,
        renderCell: (params) =>
          params.row.channel !== "personal_email" && params.row.channel !== "work_email" ? (
            <Tooltip title={t("Opens are only recorded for email")}>
              <span>-</span>
            </Tooltip>
          ) : params.row.opens ? (
            params.row.opens.toString()
          ) : (
            "0"
          ),
      },
      reactions: {
        headerName: "Reactions",
        flex: 2,
        valueFormatter: (value, row) =>
          isChatChannel(row.channel) ? (value ?? 0) : (value ?? "-"),
      },
      channel: {
        headerName: "Channel",
        flex: 2,
        renderCell: ({ value }) => <ChannelIcon channel={value} />,
      },
      id: {
        disableExport: true,
        headerName: "Actions",
        flex: 2,
        renderCell: ({ row }) => {
          return <MomentCardActions moment={row} small switchable={false} />;
        },
      },
    }),
    [t, moment_stats_v2],
  );
}
