import FileUpload from "@mui/icons-material/FileUpload";
import {
  Box,
  CircularProgress,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import { snakeCase } from "lodash-es";
import { FC } from "react";
import { DropzoneInputProps } from "react-dropzone/.";
import { useTranslation } from "react-i18next";
import { UsableAccountPersonField } from "../../server/supabase-segment-field-service";
import { SegmentCondition } from "../../types";

interface RenderInputProps {
  params: AutocompleteRenderInputParams;
  isDragActive: boolean;
  openFileUpload: () => void;
  loading: boolean;
  parsing: boolean;
  condition: SegmentCondition;
  exportCsvData: string;
  handleEntries: (entries: string[]) => void;
  error?: string;
  disabled?: boolean;
  segmentField?: UsableAccountPersonField;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
}

export const RenderInput: FC<RenderInputProps> = ({
  params,
  isDragActive,
  openFileUpload,
  loading,
  parsing,
  condition,
  handleEntries,
  exportCsvData,
  error,
  disabled,
  segmentField,
  getInputProps,
}) => {
  const { t } = useTranslation();

  if (isDragActive) {
    return (
      <Box
        sx={{
          mt: 0.4,
          border: "1px dashed",
          borderColor: "divider",
          borderRadius: 1,
          p: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <FileUpload
          onClick={openFileUpload}
          sx={{
            "&:hover": {
              cursor: "pointer",
              opacity: 0.5,
            },
            opacity: 0.5,
          }}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 0.4 }}>
      <TextField
        {...params}
        error={Boolean(error)}
        helperText={
          error ? (
            error
          ) : condition.values.length > 0 ? (
            <Link href={encodeURI(exportCsvData)} download={`${snakeCase(condition.field)}.csv`}>
              <Typography variant="caption">{t("Export CSV of existing values")}</Typography>
            </Link>
          ) : null
        }
        label={segmentField?.display_name ?? t("Value")}
        variant="standard"
        onPaste={(e) => {
          const pastedData = e.clipboardData.getData("Text");
          handleEntries(pastedData.split(",").filter((x) => x.trim().length > 0));
          e.preventDefault();
        }}
        slotProps={{
          input: {
            ...params.InputProps,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore https://github.com/mui/material-ui/issues/20160#issuecomment-600277849
            "data-testid": "segment-value-input",
            endAdornment: (
              <>
                {loading || parsing ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  condition.values?.length === 0 && (
                    <InputAdornment position="end">
                      <input data-testid="file-dropzone" {...getInputProps()} disabled={disabled} />
                      <Tooltip
                        title={
                          <Link
                            href="https://help.changeengine.com/Create-Segments-via-CSV-Upload-6ac96245f4ea4dc9b4d19ca54f24472a"
                            target="_blank"
                            color="inherit"
                            underline="always"
                            rel="noreferrer"
                          >
                            {t(
                              "You can upload a CSV containing the values you want to use to segment. Click here to find out more",
                            )}
                          </Link>
                        }
                      >
                        <FileUpload
                          onClick={openFileUpload}
                          sx={{
                            "&:hover": {
                              cursor: "pointer",
                              opacity: 0.5,
                            },
                          }}
                        />
                      </Tooltip>
                    </InputAdornment>
                  )
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          },
        }}
      />
    </Box>
  );
};
