import AutoAwesome from "@mui/icons-material/AutoAwesome";
import { Button } from "@mui/material";
import { useUpdateEffect } from "@react-hookz/web";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrompts } from "../multiformat/hooks/use-prompts";

export const InspireMeButton: FC<{ onInspire: (prompt: string) => void; format: string }> = ({
  onInspire,
  format,
}) => {
  const { t } = useTranslation();

  const { loading, error, data } = usePrompts();
  const prompts = data?.[format];

  const [inspirePosition, setInspirePosition] = useState<number | undefined>();

  useUpdateEffect(() => {
    setInspirePosition(0);
  }, [format]);

  const handleInspire = useCallback(() => {
    if (!prompts) {
      return;
    }

    const promptsWithoutCurrentPrompt = prompts?.filter((_, index) => index !== inspirePosition);

    const prompt =
      promptsWithoutCurrentPrompt[Math.floor(Math.random() * promptsWithoutCurrentPrompt.length)];
    const promptIndex = prompts.indexOf(prompt);

    onInspire(prompt);
    setInspirePosition(promptIndex);
  }, [prompts, onInspire, inspirePosition]);

  if (error) {
    return null;
  }

  return (
    <Button
      data-analytics-id="create-content-inspire-me"
      onClick={handleInspire}
      size="small"
      variant="text"
      disabled={loading}
      startIcon={<AutoAwesome />}
    >
      {t("Inspire me")}
    </Button>
  );
};
