import { Database } from "../server/supabase/types/database-definitions";
import { INTERVAL } from "./iso8601";

export const ZERO_INTERVAL = "00:00:00" as INTERVAL;

export type IntervalJson = Database["public"]["Tables"]["schedule"]["Row"]["interval_json"];

function extract(interval: INTERVAL, part: number): number {
  return parseInt(interval.split(" ")[part]);
}

export function extractAmount(interval: INTERVAL): number {
  return extract(interval, 0);
}

export function formatInterval(interval: INTERVAL | null): string {
  if (interval === null) return "";
  const amount = extract(interval, 0);
  if (isNaN(amount)) return "";

  return formatIntervalFromParts(amount !== 0 ? extractIntervalParts(interval) : {});
}

export function formatIntervalFromParts({
  years = 0,
  months = 0,
  days = 0,
}: {
  years?: number;
  months?: number;
  days?: number;
}): string {
  if (years === 0 && months === 0 && days === 0) return "day of";

  const formattedParts: string[] = [];
  if (days !== 0) {
    const direction = dir(days);
    const absDays = Math.abs(days);
    const dayLabel = pluralize(absDays, "day");
    formattedParts.push(`${absDays} ${dayLabel} ${direction}`);
  }

  if (months !== 0) {
    const direction = dir(months);
    const absMonths = Math.abs(months);
    const monthLabel = pluralize(absMonths, "month");
    formattedParts.push(`${absMonths} ${monthLabel} ${direction}`);
  }

  if (years !== 0) {
    const direction = dir(years);
    const absYears = Math.abs(years);
    const yearLabel = pluralize(absYears, "year");
    formattedParts.push(`${absYears} ${yearLabel} ${direction}`);
  }

  return formattedParts.join(", ");
}

export function extractIntervalParts(interval: INTERVAL | null): {
  years: number;
  months: number;
  days: number;
} {
  if (interval === null) return { years: 0, months: 0, days: 0 };

  const regex = /(?:(-?\+?\d+)\s*year[s]?)?\s*(?:(-?\+?\d+)\s*mons?)?\s*(?:(-?\+?\d+)\s*day[s]?)?/;
  const match = interval.match(regex);

  if (!match) return { years: 0, months: 0, days: 0 };

  const years = match[1] ? parseInt(match[1], 10) : 0;
  const months = match[2] ? parseInt(match[2], 10) : 0;
  const days = match[3] ? parseInt(match[3], 10) : 0;

  return { years, months, days };
}

function pluralize(n: number, unitSingular: "day" | "month" | "year"): string {
  return Math.abs(n) === 1 ? unitSingular : `${unitSingular}s`;
}

function dir(n: number): "before" | "after" {
  return n > 0 ? "after" : "before";
}

export function toDays(interval: INTERVAL | null): number {
  if (interval === null) return 0;

  const amount = extractAmount(interval);

  if (isNaN(amount)) return 0;
  if (interval.includes("year") && interval.includes("day"))
    return amount * 365 + extract(interval, 2);
  if (interval.includes("year")) return amount * 365;
  return amount;
}

export function toSeconds(interval: INTERVAL | null): number {
  return toDays(interval) * 24 * 60 * 60;
}
export function rawIntervalToSeconds(interval_json: IntervalJson): number {
  if (interval_json === null) return 0;

  const { years, months, weeks, days } = interval_json;

  let totalSeconds = 0;

  if (years) totalSeconds += years * 365 * 24 * 60 * 60;
  if (months) totalSeconds += months * 30 * 24 * 60 * 60;
  if (weeks) totalSeconds += weeks * 7 * 24 * 60 * 60;
  if (days) totalSeconds += days * 24 * 60 * 60;

  return totalSeconds;
}

export type OffsetUnit = "days" | "weeks" | "months" | "years";
export type DateOffset = 1 | -1;
export type Offset = {
  amount: number;
  unit: OffsetUnit;
  dateOffset: DateOffset;
};
function units(part: string): OffsetUnit {
  if (part.includes("year")) return "years";
  if (part.includes("mon")) return "months";
  if (part.includes("week")) return "weeks";
  return "days";
}
function toOffset(parts: string[]): Offset {
  const amount = parseInt(parts[0]);
  return { amount: Math.abs(amount), unit: units(parts[1]), dateOffset: amount > 0 ? 1 : -1 };
}

export function toOffsets(interval: INTERVAL): Offset[] {
  if (interval === null) return [];

  const parts = interval.split(" ");
  if (parts.length < 2) return [];
  return [toOffset(parts.slice(0, 2)), ...(parts.length > 3 ? [toOffset(parts.slice(2))] : [])];
}

function offsetToInterval(offset: Offset): string {
  return `${offset.amount * offset.dateOffset} ${offset.unit}`;
}

export function toInterval(offsets: Offset[]): INTERVAL {
  if (offsets.length === 0) return ZERO_INTERVAL;

  return offsets.map(offsetToInterval).join(" ") as INTERVAL;
}
