import Close from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  Grid2,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { sortBy } from "lodash-es";
import { FC, useMemo } from "react";
import { FieldErrorsImpl } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { noNullFields } from "../../../server/supabase/base-supabase-service";
import { AccountPersonField } from "../../event/server/supabase-account-person-field-service";
import { UsableAccountPersonField } from "../server/supabase-segment-field-service";
import { SegmentCondition } from "../types";
import { filterFieldOperators } from "./segment-operation/operators";
import { OperationSelect } from "./segment-operation/select";
import { SegmentValueField } from "./segment-value-field";
import { ChildError, TopLevelErrorAlert } from "./top-level-error-alert";

export const SegmentConditionView: FC<{
  handleFieldChange: (value: SegmentCondition) => void;
  removeItem?: () => void;
  disabled?: boolean;
  condition: SegmentCondition;
  error?: ChildError<FieldErrorsImpl<SegmentCondition>>;
  operation: string | null;
  showValidationErrors: boolean;
  segmentFields: UsableAccountPersonField[];
  recipientCondition?: SegmentCondition;
}> = ({
  handleFieldChange,
  condition,
  error,
  removeItem,
  disabled,
  operation,
  showValidationErrors,
  segmentFields,
  recipientCondition,
}) => {
  const { t } = useTranslation();
  if (!noNullFields(segmentFields)) throw new Error("Bad data");

  const segmentField = segmentFields.find((item) => condition.field === item.key);
  const deprecatedSegmentField = segmentFields.find((f) => f.key === condition.field && !f.usable);

  const fieldType =
    segmentField?.field_type ?? missingConditionToSegmentField(condition).field_type;

  return (
    segmentFields && (
      <>
        {error && (
          <TopLevelErrorAlert<FieldErrorsImpl<SegmentCondition>>
            errors={[error as FieldErrorsImpl<SegmentCondition>]}
          />
        )}
        <Box
          sx={(theme) => ({
            display: "flex",
            borderRadius: 1,
            backgroundColor: theme.palette.background.default,
            p: 2,
          })}
          data-testid="segment-condition"
        >
          <Grid2 container key={condition.id} spacing={2} sx={{ flexBasis: "100%" }}>
            <Grid2 size={{ sm: 8, md: 4 }}>
              <Box display="flex" gap={2}>
                {operation && (
                  <Box pt={2.5}>
                    <Typography variant="body2" data-testid="operationHint">
                      {operation}
                    </Typography>
                  </Box>
                )}
                <Box flexGrow={1}>
                  <SegmentConditionAutoComplete
                    condition={condition}
                    segmentFields={segmentFields}
                    handleFieldChange={handleFieldChange}
                    disabled={disabled}
                    errMsg={showValidationErrors ? error?.field?.message : undefined}
                    deprecatedSegmentField={deprecatedSegmentField}
                  />
                </Box>
              </Box>
            </Grid2>
            <Grid2 size={{ sm: 4, md: 2 }}>
              <FormControl sx={{ mr: 1 }} fullWidth error={Boolean(error?.operation)}>
                <InputLabel id="operator-label" sx={{ ml: -2, mt: 1 }}>
                  {t("Operator")}
                </InputLabel>
                <OperationSelect
                  fieldType={fieldType}
                  condition={condition}
                  handleFieldChange={handleFieldChange}
                  disabled={disabled}
                />
                {error?.operation && (
                  <FormHelperText>
                    {showValidationErrors && error.operation?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid2>
            <Grid2 size={{ sm: 12, md: 6 }}>
              <FormControl fullWidth>
                <SegmentValueField
                  condition={condition}
                  handleFieldChange={(v) => handleFieldChange({ ...condition, values: v })}
                  error={error?.values?.message}
                  disabled={disabled}
                  segmentField={segmentField}
                  fieldType={fieldType}
                  recipientCondition={recipientCondition}
                />
              </FormControl>
            </Grid2>
            {deprecatedSegmentField?.key === condition.field ? (
              <Grid2 size={{ sm: 12 }}>
                <Alert severity="warning">
                  {t("This field does not have any associated data")}
                </Alert>
              </Grid2>
            ) : null}
          </Grid2>
          <Box flexBasis="30px">
            {removeItem && (
              <Tooltip title={t("Remove")} placement="right">
                <IconButton
                  data-analytics-id="segment-form-condition"
                  aria-label={t("Remove")}
                  data-testid="remove-condition"
                  onClick={removeItem}
                  disabled={disabled}
                  sx={(theme) => ({
                    color: theme.palette.primary.light,
                    left: "15px",
                    top: "-5px",
                  })}
                  size="small"
                >
                  <Close />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </>
    )
  );
};

export const SegmentConditionAutoComplete: FC<{
  condition: SegmentCondition;
  segmentFields: UsableAccountPersonField[];
  handleFieldChange: (value: SegmentCondition) => void;
  disabled?: boolean;
  errMsg?: string;
  deprecatedSegmentField?: UsableAccountPersonField;
}> = ({
  condition,
  segmentFields,
  handleFieldChange,
  disabled,
  errMsg,
  deprecatedSegmentField,
}) => {
  const { t } = useTranslation();

  const missingField = segmentFields.findIndex((f) => f.key === condition.field) === -1;

  // First employee fields, then events, then audience (recipient type)
  // Secondarily sort by display name
  const sortedSegmentFields = useMemo(
    () =>
      sortBy(
        [
          ...segmentFields,
          ...(missingField ? [missingConditionToSegmentField(condition)] : []),
        ].filter(
          (f) => f.key === deprecatedSegmentField?.key || f.key === condition.field || f.usable,
        ),
        [
          (f) => {
            if (f.key === deprecatedSegmentField?.key) return 3;
            if (f.key?.startsWith("event")) return 1;
            if (f.key?.startsWith("recipient_type")) return 2;
            return 0;
          },
          (f) => f.display_name,
        ],
      ).map((f) => ({
        ...f,
        disabled:
          f.key === deprecatedSegmentField?.key || (missingField && f.key === condition.field),
      })),
    [segmentFields, deprecatedSegmentField, condition, missingField],
  );

  if (!noNullFields(sortedSegmentFields)) throw new Error("Bad data");

  return (
    <FormControl sx={{ mr: 1 }} fullWidth error={Boolean(errMsg)}>
      <Autocomplete
        disablePortal={false}
        id={`field-${condition.id}`}
        fullWidth
        options={sortedSegmentFields}
        getOptionLabel={(option) => option?.display_name ?? ""}
        getOptionDisabled={(option) => option.disabled}
        groupBy={(option) => {
          if (option.key === deprecatedSegmentField?.key) return t("Deprecated fields");

          if (option.key.startsWith("event")) return t("Events");

          if (option.key.startsWith("recipient_type")) return t("Audience");

          return t("Fields");
        }}
        onChange={(_, value) => {
          handleFieldChange({
            ...condition,
            field: value?.key ?? "",
            ...makeDefaultOperationAndValue(value?.field_type),
          });
        }}
        value={sortedSegmentFields.find((f) => f.key === condition.field) ?? null}
        disabled={disabled}
        renderInput={(params) => <TextField {...params} label={t("Field")} variant="standard" />}
      />

      {Boolean(errMsg) && <FormHelperText>{errMsg}</FormHelperText>}
    </FormControl>
  );
};

function makeDefaultOperationAndValue(fieldType?: AccountPersonField["field_type"]): {
  operation: SegmentCondition["operation"];
  values: SegmentCondition["values"];
} {
  const defaultOperation = fieldType ? filterFieldOperators[fieldType].defaultOperator : "IS";

  switch (defaultOperation) {
    case "IS TRUE":
      return {
        operation: "IS",
        values: ["true"],
      };
    default:
      return {
        operation: defaultOperation,
        values: [],
      };
  }
}

export function missingConditionToSegmentField(
  condition: SegmentCondition,
): UsableAccountPersonField {
  return {
    id: condition.id,
    key: condition.field,
    display_name: (condition.field.startsWith("event_")
      ? condition.field.replace("event_", "")
      : condition.field
    )
      .replace("recipient_type_", "")
      .replace("_", " "),
    field_type: condition.field.startsWith("event_") ? "DATE" : "TEXT",
    usable: false,
  };
}
