import { uniqWith } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import { SegmentWithMoments } from "../../../server/supabase-segment-service";
import { SegmentRowWithOptions } from "../types";

const rowOptions = { preventEditing: false, preventDeletion: false, preventEditingReason: "N/A" };

export function useProcessedSegments(
  segments: SegmentWithMoments[],
  showArchived?: boolean,
): SegmentRowWithOptions[] {
  const getProcessedSegments = useCallback(
    () =>
      uniqWith(segments, (currentVal, otherVal) => currentVal.id === otherVal.id)
        .map((datum) => ({
          ...datum,
          id: datum.id!,
          moments: datum.moments ?? [],
          options: rowOptions,
        }))
        .filter((datum) =>
          showArchived ? !datum.is_default : !datum.is_default && datum.deleted_at === null,
        )
        .filter(({ name, moments }) => Boolean(name) || moments?.length === 1),
    [segments, showArchived],
  );
  const [processedSegments, setProcessedSegments] =
    useState<SegmentRowWithOptions[]>(getProcessedSegments);

  useEffect(() => {
    setProcessedSegments(getProcessedSegments());
  }, [getProcessedSegments]);

  return processedSegments;
}
