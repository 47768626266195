import { Alert, LinearProgress } from "@mui/material";
import { sortBy } from "lodash-es";
import { FC } from "react";
import toast from "react-hot-toast";
import { useSupabaseCallback, useSupabaseLoader } from "../../../server/supabase/hooks";
import { propertyNotEmpty } from "../../../utils/not-empty";
import { CrudDataGrid } from "../../generic/components/crud-data-grid/crud-data-grid";
import { Row } from "../../generic/components/crud-data-grid/types";
import {
  AccountLimitKey,
  AdminAccountLimit,
  CeAdminSupabaseAccountService,
} from "../../generic/server/supabase-account-service";
import { Columns, Keys } from "../types";

const booleanColumns: AccountLimitKey[] = ["allow_all_and_upcoming_segment"];

export const AccountDataGrid: FC = () => {
  const { data, loading, error } = useSupabaseLoader<
    { data: Columns[]; keys: string[] },
    "ce_admin"
  >(
    async ({ supabase }) => {
      const service = new CeAdminSupabaseAccountService(supabase);

      const [
        { data: accountLimitInfo, error: accountLimitInfoError },
        { data: limitKeys, error: limitKeysError },
      ] = await Promise.all([service.getAccountLimits(), service.getKeys()]);

      if (accountLimitInfoError || limitKeysError) {
        throw new Error("Could not load account limit info");
      }

      const keys = (limitKeys ?? []).filter(propertyNotEmpty("key")).map((x) => x.key);

      const makeColumns = (limitInfo: AdminAccountLimit["limits"]): Keys => {
        return Object.fromEntries(keys.map((key) => [key, limitInfo?.[key] ?? 0]));
      };

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const accountData: Columns[] = (accountLimitInfo ?? []).map(({ limits, account_type, ...rest }) => ({
        ...rest,
        ...makeColumns(limits),
      }));

      return {
        data: sortBy(accountData, "name"),
        keys,
      };
    },
    [],
    "ce_admin",
  );

  const persist = useSupabaseCallback(
    async ({ supabase }, row: Columns) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, name, business_name, account_type, usage, churned,...limits } = row;

      const { error: err } = await new CeAdminSupabaseAccountService(supabase).setAccountLimits(
        id,
        limits as { [key: string]: number },
        business_name,
        usage,
        churned
      );
      if (err) {
        toast.error("Could not save account limit info");
        return;
      }

      toast.success(`Saved account limit info for ${name}`);

      return row;
    },
    [],
    "ce_admin",
  );

  if (loading) return <LinearProgress />;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  return (
    <>
      <CrudDataGrid<Row<Columns>>
        initialRows={data.data}
        columns={{
          name: {
            headerName: "Account Name",
            width: 200,
            editable: false,
          },
          business_name: {
            headerName: "Business Name",
            width: 200,
            editable: true,
          },
          usage: {
            headerName: "Account Usage",
            width: 200,
            editable: true,
            type: 'singleSelect',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            valueOptions: [
              "CLIENT",
              "DEMO",
              "TESTING"
            ]
          },
          churned: {
            headerName: "Churned",
            width: 200,
            editable: true,
            type: 'boolean'
          },
          ...Object.fromEntries(
            data.keys.map((key) => [
              key,
              {
                headerName: key
                  .split("_")
                  .map((x) => x[0].toUpperCase() + x.slice(1))
                  .join(" "),
                editable: true,
                width: 200,
                ...(booleanColumns.includes(key as AccountLimitKey)
                  ? {
                      type: "boolean",
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      valueSetter: (value: boolean, row: any) =>
                        ({ ...row, [key]: +value }) as Row<Columns>,
                    }
                  : { type: "number" }),
              },
            ]),
          ),
        }}
        gridOverrides={{
          initialState: {
            pinnedColumns: { left: ["name"], right: ["actions"] },
            pagination: { paginationModel: { pageSize: 10 } },
            filter: {
              filterModel: {
                items: [{ id: 'churned', field: "churned", operator: "is", value: false }],
              },
            },
          },
          pagination: true,
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onUpdate={persist}
      />
    </>
  );
};
