import { RefObject } from "@fullcalendar/core/preact";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { Box, Button } from "@mui/material";
import { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { uuidv4 } from "../../../../utils/uuid";
import { MomentFormMoment } from "../../../moment/types/moment-form-moment";

export const AddFilterButton: FC<{ containerRef: RefObject<HTMLDivElement> }> = ({
  containerRef,
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext<MomentFormMoment>();

  const { append } = useFieldArray({ control, name: "segment.conditions" });

  return (
    <Box>
      <Button
        data-analytics-id="segment-form-add-filter"
        onClick={() => {
          append({
            id: uuidv4(),
            field: "",
            operation: "IS",
            values: [],
            invert: false,
          });
          // scroll to the bottom of the form
          containerRef.current?.scrollTo?.({
            top: containerRef.current.scrollHeight,
            behavior: "smooth",
          });
        }}
        variant="outlined"
        color="primary"
        startIcon={<AddCircleOutline />}
      >
        {t("Add filter")}
      </Button>
    </Box>
  );
};
