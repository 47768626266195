import { useFormContext } from "react-hook-form";
import { config } from "../../../../../config";
import { useSupabaseCallback } from "../../../../../server/supabase/hooks";
import { SupabaseCustomEmailService } from "../../../../channels/email/server/supabase-custom-email-service";
import { ChannelName } from "../../../../channels/types";
import { EmailMomentLoader } from "../../../../email-moment/server/email-moment-loader";
import { EmailMoment } from "../../../../email-moment/types";
import { SerialisedEditorDesign } from "../../../../message/editor/types/serialised-editor-design";
import { SupabaseSlackAliasDefaultService } from "../../../../slack/server/slack-supabase-service";
import { MomentFormMoment } from "../../../types/moment-form-moment";
import { CHAT_CHANNELS, EMAIL_CHANNELS } from "../channel";

export function useHandleReset(newsletter?: boolean): (newChannel?: ChannelName) => Promise<void> {
  const { reset } = useFormContext<MomentFormMoment>();

  return useSupabaseCallback(
    async ({ supabase }, newChannel?: ChannelName) => {
      // Initialize services
      const slackAliasService = new SupabaseSlackAliasDefaultService(supabase);
      const customEmailService = new SupabaseCustomEmailService(supabase);
      const emailMomentLoader = new EmailMomentLoader(supabase);

      // Get sender information based on channel type
      const getSenderInfo = async (): Promise<string | null> => {
        if (!newChannel) return null;

        if (newChannel === "slack") {
          const { data } = await slackAliasService.getSingle();
          return data?.slack_alias_id || null;
        }

        if (!newsletter && EMAIL_CHANNELS.includes(newChannel)) {
          const { data } = await customEmailService.getDefault();
          return data?.address || config.defaultEmailFrom;
        }

        if (CHAT_CHANNELS.includes(newChannel)) {
          const { data } = await slackAliasService.getSingle();
          return data?.slack_alias_id || null;
        }

        return null;
      };

      // Get email moment if needed
      const getEmailMoment = async (): Promise<EmailMoment | null> => {
        if (newChannel && EMAIL_CHANNELS.includes(newChannel) && newsletter) {
          return emailMomentLoader.loadNew();
        }
        return null;
      };

      // Build new form values
      const newValues = {
        channel: newChannel ?? null,
        email_moment: await getEmailMoment(),
        chat_moment: null,
        additional_recipients: [],
        from: await getSenderInfo(),
        use_lexical_editor: !newsletter,
        design: {} as SerialisedEditorDesign,
        email_subject: null,
        body: null,
      };

      reset((values) => ({ ...values, ...newValues }), { keepDirty: true });
    },
    [reset, newsletter],
  );
}
