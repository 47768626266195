import Close from "@mui/icons-material/Close";
import ControlPoint from "@mui/icons-material/ControlPoint";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilePicker } from "../generic/components/file-picker/file-picker";
import { ImagesService } from "../../server/cloudflare/images-service";
import { useToken } from "../../server/auth/use-token";
import { NameSpaceURL, uuidv4, uuidv5 } from "../../utils/uuid";

const addText = "Drag and drop a file here, or upload from your computer";
const formatsText = "Supported formats: pdf, png, gif, jpg";

export const UploadFileModal: FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const getToken = useToken();
  const [currentValue, setCurrentValue] = useState<string | null>(null);
  const [fileTitle, setFileTitle] = useState<string | null>(null);

  const handleUpload = useCallback(
    async (file: File) => {
      const response = await new ImagesService().upload(
        file,
        uuidv5(NameSpaceURL, uuidv4()),
        await getToken(),
        file.type,
      );

      if (!response.success) {
        console.error("Upload failed");
        return Promise.reject(new Error("Upload failed"));
      }

      setFileTitle(file.name);
      setCurrentValue(response.url);
      return Promise.resolve(file);
    },
    [getToken],
  );

  const handleClose = useCallback((): void => {
    setCurrentValue(null);
    setFileTitle("");
    onClose();
  }, [onClose]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle display="flex" justifyContent="space-between" component="div">
        <Typography variant="h6" flexGrow={1} mt={1}>
          {t("Upload file")}
        </Typography>
        <IconButton
          onClick={handleClose}
          data-analytics-id="upload-file-modal-close"
          title={t("Close")}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FilePicker
            value={currentValue}
            handleUpload={handleUpload}
            accept={{ "image/*": [".png", ".gif", ".jpg", ".jpeg"], "application/pdf": [".pdf"] }}
            text={{
              remove: t("Remove file"),
              uploadError: t("Only one file can be uploaded at a time"),
            }}
            content={
              <Box sx={{ p: 2 }}>
                <Typography variant="h1" sx={{ textAlign: "center" }}>
                  <ControlPoint color="primary" fontSize="large" />
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                  {addText}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: "center" }} color="text.secondary">
                  {formatsText}
                </Typography>
              </Box>
            }
            handleRemove={useCallback(() => setFileTitle(null), [])}
          />
          <TextField
            value={fileTitle ?? ""}
            id="file-title"
            placeholder={t("Title")}
            onChange={(e) => setFileTitle(e.target.value)}
          ></TextField>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyItems: "right", px: 2, mb: 2 }}>
        <Button onClick={handleClose} data-analytics-id="upload-file-modal-cancel">
          {t("Cancel")}
        </Button>
        <Button data-analytics-id="upload-file-modal-begin-upload">{t("Upload")}</Button>
      </DialogActions>
    </Dialog>
  );
};
