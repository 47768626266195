import Visibility from "@mui/icons-material/Visibility";
import { IconButton, Tooltip } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { UUID } from "../../../../utils/uuid";

export const ViewAction: FC<{ id: UUID | null; title: string | null }> = ({ id, title }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("View moment: {{title}}", { title })}>
      <IconButton data-analytics-id="moment-card-view" href={`/moments/${id || ""}`}>
        <Visibility fontSize="small" sx={{ marginLeft: 0 }} data-testid="moment-view-icon" />
      </IconButton>
    </Tooltip>
  );
};
