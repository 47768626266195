export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

type NonNullableProperty<T, K extends keyof T> = T & Record<K, NonNullable<T[K]>>;

export function propertyNotEmpty<T, K extends keyof T>(
  property: K,
): (obj: T) => obj is NonNullableProperty<T, K> {
  return (obj: T): obj is NonNullableProperty<T, K> => {
    return obj[property] !== null && obj[property] !== undefined;
  };
}

export function nestedPropertyNotEmpty<T, K extends keyof T, L extends keyof T[K]>(
  property: K,
  nestedProperty: L,
): (obj: T) => obj is NonNullableProperty<T, K> {
  return (obj: T): obj is NonNullableProperty<T, K> => {
    return (
      obj[property] !== null &&
      obj[property] !== undefined &&
      obj[property][nestedProperty] !== null &&
      obj[property][nestedProperty] !== undefined
    );
  };
}
