import PeopleRounded from "@mui/icons-material/PeopleRounded";
import { Box, Tooltip } from "@mui/material";
import { FC, ReactElement } from "react";
import { capitalizeEachWord } from "../../../utils/capitalize";
import { notEmpty } from "../../../utils/not-empty";
import { ToTarget } from "../../to/use-to-target";

export const SegmentChip: FC<{
  segmentName?: string | null;
  toTarget: ToTarget;
}> = ({ segmentName, toTarget }) => {
  const [icon, tooltipText] = switchMomentIcons(toTarget);
  return segmentName ? (
    <Box sx={{ display: "inline" }}>
      {icon && (
        <Tooltip
          title={tooltipText}
          placement="top"
          arrow
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          {icon}
        </Tooltip>
      )}
      <Box component={"span"} sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {segmentName}
      </Box>
    </Box>
  ) : (
    <>---</>
  );
};

export const ChannelChip: FC<{
  moment: {
    additional_recipients_json?:
      | { display?: string | null; destination: string; teams_link_name?: string | null }[]
      | null;
  };
}> = ({ moment }) => {
  const channelText = moment.additional_recipients_json
    ? moment.additional_recipients_json
        .filter(notEmpty)
        .map(
          (recipient) =>
            `#${recipient.display ?? recipient.destination}${
              recipient.teams_link_name ? ` (${recipient.teams_link_name})` : ""
            }`,
        )
        .join(", ")
    : null;

  return channelText ? (
    <Box sx={{ display: "inline" }}>
      <Tooltip title={channelText} placement="top">
        <p
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {channelText}
        </p>
      </Tooltip>
    </Box>
  ) : (
    <>---</>
  );
};

export const switchMomentIcons = ({toTarget, isManagerConnection,target_connection_type}: ToTarget): [ReactElement | null, string] => {
  let icon: ReactElement | null = null;
  let tooltipText = "";

  if(isManagerConnection){
    icon = <PeopleRounded sx={{ verticalAlign: "middle", mr: 1 }} />;
  }

  if (toTarget === 'manager') {
    tooltipText = "Managers will receive the moments for their employees in this segment";
  }
  if(toTarget === 'connection'){
    // TODO can make this more plurally if needed
    const title = capitalizeEachWord(`${target_connection_type}s`)
    tooltipText = `${title} will receive the moments for their connections in this segment`
  }


  return [icon, tooltipText];
};
