import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Box, Button, Divider, Menu } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import { useFlag } from "../../../server/optimizely";
import { FilterSection } from "../../filter/filter-section";
import { queryString } from "../../filter/queryString";

export const ContentLibraryFilter: FC = () => {
  const { t } = useTranslation();
  const [content_hosting] = useFlag("content_hosting");
  const contentTypes = useMemo(() => {
    const cs: { [key: string]: string } = {
      "Logo Landscape": "Landscape Logo",
      "Logo Square": "Square Logo",
      "TV Display": "TV Display",
      Certificate: "Certificate",
      "Zoom Background": "Zoom Background",
      Poster: "Poster",
      "Employee FAQ": "FAQ",
      "Manager One-Pager": "Manager One-Pager",
      "Header Graphic": "Horizontal Banner",
    };
    return content_hosting ? { ...cs, ...{ "Uploaded File": "Uploaded File" } } : cs;
  }, [content_hosting]);

  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [selectedContentTypes, setSelectedContentTypes] = useState<string[]>([]);
  const [dialog, setDialog] = useState<boolean>(false);
  const filterIcon = useRef();

  useEffect(() => {
    setSelectedContentTypes(queryString(urlSearchParams, "format", contentTypes));
  }, [contentTypes, urlSearchParams]);

  const handleApplyFilter = useCallback(() => {
    const params = new URLSearchParams();
    selectedContentTypes.forEach((c) => params.append("format", c));
    setUrlSearchParams(params);
    setDialog(false);
  }, [selectedContentTypes, setUrlSearchParams]);

  const handleClose = useCallback(() => {
    setDialog(false);
  }, [setDialog]);

  return (
    <>
      <Box ref={filterIcon}>
        <Button
          data-analytics-id="content-library-filter"
          onClick={() => setDialog(true)}
          aria-label="filterButton"
          startIcon={<FilterAltOutlinedIcon />}
        >
          {t("Filter")}
        </Button>
      </Box>
      <Menu
        anchorEl={filterIcon.current}
        open={dialog}
        onClose={() => setDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            color: "text.primary",
            pb: 3,
            px: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <FilterSection
              title="Content types"
              options={contentTypes}
              selectedItems={selectedContentTypes}
              onChange={(values) => {
                setSelectedContentTypes(values);
              }}
              omitAllOption
            />
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              pt: 3,
            }}
          >
            <Box sx={{ flexGrow: 1 }} />

            <Button
              data-analytics-id="content-library-filter-menu-cancel"
              variant="outlined"
              type="reset"
              onClick={handleClose}
            >
              {t("Cancel")}
            </Button>

            <Button
              data-analytics-id="content-library-filter-menu-apply"
              type="submit"
              sx={{ ml: 2 }}
              variant="contained"
              onClick={handleApplyFilter}
            >
              {t("Apply Filter")}
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};
