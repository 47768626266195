import Repeat from "@mui/icons-material/Repeat";
import { Autocomplete, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FC, useMemo } from "react";
import { CeTextField } from "../../../generic/components/form-fields/ce-text-field";
import { Login } from "../../../pipe/types";

export const AccountSwitcher: FC<{ logins?: { [k: string]: Login } }> = ({ logins = {} }) => {
  const sortedLogins = useMemo(
    () =>
      Object.values(logins)
        ?.filter((login) => !login.url.includes(window.location.hostname))
        .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()),
    [logins],
  );

  if (!sortedLogins || sortedLogins.length < 1) return null;

  return (
    <MenuItem key="loginsFilter">
      <ListItemIcon>
        <Repeat />
      </ListItemIcon>
      <ListItemText>
        <Autocomplete
          options={sortedLogins}
          getOptionLabel={(option) => option.name}
          style={{ width: "100%" }}
          onChange={(event, login) => {
            if (login) {
              window.open(login.url, login.id);
            }
          }}
          renderInput={(params) => (
            <CeTextField
              {...params}
              placeholder="Switch Account"
              variant="editabletext"
              sx={{ width: "100%" }}
            />
          )}
          slotProps={{
            popper: {
              popperOptions: {
                placement: "auto-end",
              },
            },
            paper: {
              sx: {
                border: "1px solid",
                borderColor: "divider",
                width: "400px",
              },
            },
          }}
        />
      </ListItemText>
    </MenuItem>
  );
};
