import Home from "@mui/icons-material/Home";
import LanguageOutlined from "@mui/icons-material/LanguageOutlined";
import { Box, Button, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Pencil } from "../../../styles/icons/pencil";
import { Item } from "./sidebar/item";
import { useIsActive } from "./sidebar/navigation";
import { Section } from "./sidebar/section";
import { Sidebar } from "./sidebar/sidebar";

export const CeAdminSidebar: FC = () => {
  const { t } = useTranslation();
  const isActive = useIsActive();
  return (
    <Sidebar footer={null}>
      <Stack sx={{ height: "100%" }}>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{ p: 3 }}
          justifyContent="space-between"
        >
          <Typography variant="h6">ChangeEngine Admin</Typography>
          <Button data-analytics-id="ce-admin-logout" href="/logout" variant="outlined">
            Logout
          </Button>
        </Stack>
        <Stack
          component="nav"
          spacing={2}
          sx={{
            flexGrow: 1,
            px: 2,
          }}
        >
          <CreateAccountButton />
          <Section>
            <>
              <Item
                title={t("Accounts")}
                icon={<Home />}
                onClick={{ type: "href", value: "/ce_admin" }}
                active={isActive("/ce_admin")}
              />
              <Item
                title={t("DH Custom Brand IDs")}
                icon={<Pencil />}
                onClick={{ type: "href", value: "/ce_admin/design_huddle_custom_brands" }}
                active={isActive("/ce_admin/design_huddle_custom_brands")}
              />
              <Item
                title={t("Discover")}
                icon={<LanguageOutlined />}
                onClick={{ type: "href", value: "/ce_admin/manage_discover" }}
                active={isActive("/ce_admin/manage_discover")}
              />
            </>
          </Section>
        </Stack>
      </Stack>
    </Sidebar>
  );
};

const CreateAccountButton: FC = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Button
        data-analytics-id="ce-admin-create-account"
        size="large"
        variant="contained"
        href="/ce_admin/create_account"
        fullWidth
        sx={{ mx: 1 }}
      >
        Create Account
      </Button>
    </Box>
  );
};
