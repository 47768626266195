import Sms from "@mui/icons-material/Sms";
import { Box, BoxProps } from "@mui/material";
import { useUpdateEffect } from "@react-hookz/web";
import { FC, useCallback, useState } from "react";
import { PersonalEmailIcon, WorkEmailIcon } from "../../../styles/icons/email";
import { SlackIcon } from "../../../styles/icons/slack";
import { TeamsIcon } from "../../../styles/icons/teams";
import { useSettings } from "../../../utils/hooks/use-settings";
import { useAnalytics } from "../../analytics/analytics";
import { ChannelProps } from "../../channels/components/channel-card";
import { ChannelName } from "../../channels/types";
import { ExpandButton } from "../../generic/components/expand-button/expand-button";

export const slackConfig: ChannelProps = {
  name: "slack",
  buttonText: "Slack",
  iconSmall: (width?: number, height?: number) => <SlackIcon sx={{ width, height }} />,
};

export const workEmailConfig: ChannelProps = {
  name: "work_email",
  buttonText: "Work Email",
  iconSmall: (width?: number, height?: number) => <WorkEmailIcon sx={{ width, height }} />,
};

export const personalEmailConfig: ChannelProps = {
  name: "personal_email",
  buttonText: "Personal Email",
  iconSmall: (width?: number, height?: number) => <PersonalEmailIcon sx={{ width, height }} />,
};

export const teamsConfig: ChannelProps = {
  name: "teams",
  buttonText: "Teams",
  iconSmall: (width?: number, height?: number) => <TeamsIcon sx={{ width, height }} />,
};

export const twilioConfig: ChannelProps = {
  name: "twilio",
  buttonText: "SMS",
  iconSmall: (width?: number, height?: number) => <Sms color="primary" sx={{ width, height }} />,
};

export const channelMap: Record<Exclude<ChannelName, null>, ChannelProps> = {
  slack: slackConfig,
  teams: teamsConfig,
  work_email: workEmailConfig,
  personal_email: personalEmailConfig,
  twilio: twilioConfig,
};

export interface AccordionState<T> {
  channel?: T;
  details?: T;
  to?: T;
  when?: T;
  message?: T;
}

export type OpenState = "open" | "closed" | "confirmed";

export function useAccordionState(): {
  isOpen: (accordion: keyof AccordionState<OpenState>) => OpenState;
  setOpen: (accordion: keyof AccordionState<OpenState>, open: OpenState) => void;
  transition: (from: keyof AccordionState<OpenState>, to: keyof AccordionState<OpenState>) => void;
  AccordionToggle: FC<BoxProps>;
} {
  const { settings, saveSettings } = useSettings();
  const [state, setState] = useState<AccordionState<OpenState>>({
    channel: "open",
    details: settings.openAccordions ? "open" : "closed",
    to: settings.openAccordions ? "open" : "closed",
    when: settings.openAccordions ? "open" : "closed",
    message: settings.openAccordions ? "open" : "closed",
  });

  const isOpen = useCallback(
    (accordion: keyof AccordionState<OpenState>) => state[accordion] ?? "closed",
    [state],
  );

  useUpdateEffect(
    () =>
      setState({
        channel: settings.openAccordions ? "open" : "closed",
        details: settings.openAccordions ? "open" : "closed",
        to: settings.openAccordions ? "open" : "closed",
        when: settings.openAccordions ? "open" : "closed",
        message: settings.openAccordions ? "open" : "closed",
      }),
    [settings.openAccordions],
  );

  const { gaEvent } = useAnalytics();

  return {
    isOpen,
    setOpen: (accordion: keyof AccordionState<OpenState>, open: OpenState) => {
      setState({ ...state, [accordion]: open });
      gaEvent("accordion", { accordion, open: open === "open", event_category: "moment" });
    },
    AccordionToggle: (props) => (
      <AccordionToggle
        open={settings.openAccordions}
        setAllOpen={(open: boolean) => saveSettings({ ...settings, openAccordions: open })}
        {...props}
      />
    ),
    transition: (from: keyof AccordionState<OpenState>, to: keyof AccordionState<OpenState>) => {
      setState({ ...state, [from]: "confirmed", [to]: "open" });
    },
  };
}

export interface AccordionToggleProps {
  open: boolean;
  setAllOpen(open: boolean): void;
}
const AccordionToggle: FC<AccordionToggleProps & BoxProps> = ({
  open,
  setAllOpen,
  sx = [],
  ...props
}) => {
  return (
    <Box
      sx={[
        {
          display: "table",
          ml: "auto",
          mb: 3,
          mt: 3,
        },
        ...(sx instanceof Array ? sx : [sx]),
      ]}
      {...props}
    >
      <ExpandButton
        initialState={open}
        minimizedMessage="Open all accordions"
        expandedMessage="Minimize accordions"
        onClick={() => setAllOpen(!open)}
      />
    </Box>
  );
};
