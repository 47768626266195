import Refresh from "@mui/icons-material/Refresh";
import { Button, Stack } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { Users } from "../../../styles/icons/users";
import { IntegrationCard } from "../../generic/components/cards/integration-card";
import { SyncStatusResponse } from "../../integrations/client/hris-integration-service";
import {
  HrisConfig,
  SupabaseSuperAdminHRISService,
} from "../../integrations/server/supabase-hris-service";
import { useHrisIntegration } from "../hooks/use-hris-integration";
import { HrisIntegrationConfigureButton } from "./hrisintegration-configure-button";
import { HrisIntegrationConnectButton } from "./hrisintegration-connect-button";
import { HrisIntegrationExtraInfo } from "./hrisintegration-extra-info";
import { PersonConflictDataGrid } from "./person-conflict-data-grid";

export const HRISIntegrationCard: FC<{ onConnect?: () => Promise<void> }> = ({ onConnect }) => {
  const { t } = useTranslation();

  const {
    data: syncStatus,
    loading: loadingSyncStatus,
    reload: reloadSyncStatus,
    error: syncStatusError,
  } = useSupabaseLoader(
    async ({ supabase }) => {
      const { error: err, data } = await new SupabaseSuperAdminHRISService(
        supabase,
      ).getSyncStatus();
      if (err || !data) throw err;

      const responseData = JSON.parse(data.content) as { results: SyncStatusResponse[] };

      const result = responseData.results;
      if (!result || result.length === 0) throw new Error("No sync status found");

      const employeeSyncStatus = responseData.results.find((res) => res.model_name === "Employee");
      const status = employeeSyncStatus?.status;
      if (!status) throw new Error("No sync status found");

      return {
        model_name: employeeSyncStatus.model_name,
        last_sync_start: employeeSyncStatus.last_sync_start,
        next_sync_start: employeeSyncStatus.next_sync_start,
        is_initial_sync: employeeSyncStatus.is_initial_sync,
        status,
      };
    },
    [],
    "super_admin",
  );

  const {
    data: integration,
    loading: loadingInteration,
    reload: reloadIntegration,
    disableIntegration,
  } = useHrisIntegration();

  return (
    <IntegrationCard
      title={t("HRIS")}
      subtitle={t("Sync your employees using your HRIS system.")}
      hasIntegration={loadingInteration ? "loading" : integration ? true : false}
      disableIntegration={disableIntegration}
      connectButton={
        <HrisIntegrationConnectButton reloadIntegration={reloadIntegration} onConnect={onConnect} />
      }
      icon={<Users sx={{ color: "text.primary" }} />}
      extra={<Extra integration={integration} syncStatus={syncStatus} error={syncStatusError} />}
      extraAction={
        <>
          <Button
            data-analytics-id="hris-integration-status-refresh"
            variant="outlined"
            loading={loadingSyncStatus}
            onClick={reloadSyncStatus}
            startIcon={<Refresh />}
          >
            {t("Refresh Sync Status")}
          </Button>
          {integration && (
            <HrisIntegrationConfigureButton
              integration={integration}
              reloadIntegration={reloadIntegration}
            />
          )}
        </>
      }
    />
  );
};

const Extra: FC<{
  integration: HrisConfig | null;
  syncStatus: SyncStatusResponse | null;
  error: Error | null;
}> = ({ integration, syncStatus, error }) => {
  if (!integration) return null;

  return (
    <Stack spacing={1} mt={1}>
      <HrisIntegrationExtraInfo integration={integration} syncStatus={syncStatus} error={error} />
      <PersonConflictDataGrid />
    </Stack>
  );
};
