import { yupResolver } from "@hookform/resolvers/yup";
import Settings from "@mui/icons-material/Settings";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FC } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../server/supabase/hooks";
import { useDialog } from "../../../utils/hooks/use-dialog";
import { fromISO8601_DATE, toISO8601_DATE } from "../../../utils/iso8601";
import yup, { yupISO8601Date } from "../../../utils/yup";
import { HrisConfig, SupabaseHRISService } from "../../integrations/server/supabase-hris-service";

const schema = yup.object({
  inactive_people_cut_off_date: yupISO8601Date.nullable().default(null),
});

export const HrisIntegrationConfigureButton: FC<{
  integration: HrisConfig;
  reloadIntegration: () => void;
}> = ({ integration, reloadIntegration }) => {
  const { t } = useTranslation();
  const { handleClose, ...dialog } = useDialog();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: integration,
  });

  const { id } = integration;

  const handleSubmit = useSupabaseCallback(
    async ({ supabase }, updates: yup.InferType<typeof schema>) => {
      const { error } = await new SupabaseHRISService(supabase).update(id, updates);

      if (error) {
        toast.error(t("There was an error updating the HRIS configuration"));
        return;
      }

      toast.success(t("HRIS configuration updated"));

      handleClose();
      reloadIntegration();
    },
    [reloadIntegration, handleClose, t, id],
  );

  return (
    <>
      <Button
        data-analytics-id="hris-integration-configure"
        onClick={dialog.handleOpen}
        variant="outlined"
        color="secondary"
        startIcon={<Settings />}
      >
        {t("Configure")}
      </Button>
      <Dialog open={dialog.open} onClose={handleClose} fullWidth maxWidth="sm">
        <FormProvider {...methods}>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <DialogTitle>{t("Configure HRIS Integration")}</DialogTitle>
            <DialogContent>
              <Stack spacing={2} mt={2}>
                <Controller
                  name="inactive_people_cut_off_date"
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <DatePicker
                      {...field}
                      value={field.value ? fromISO8601_DATE(field.value) : null}
                      onChange={(value) => field.onChange(value ? toISO8601_DATE(value) : null)}
                      label={t("Inactive People Cut Off Date")}
                      slotProps={{
                        textField: {
                          id: "inactive-people-cut-off-date",
                          error: Boolean(fieldState.error),
                          helperText:
                            fieldState.error?.message ??
                            t(
                              "We won't attempt to sync inactive people with a termination date before this date.",
                            ),
                          sx: { mr: 2 },
                        },
                        field: {
                          clearable: true,
                          onClear: () => field.onChange(null),
                        },
                      }}
                    />
                  )}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                data-analytics-id="hris-integration-configure-dialog-cancel"
                onClick={handleClose}
                color="warning"
              >
                {t("Cancel")}
              </Button>
              <Button
                data-analytics-id="hris-integration-configure-dialog-save"
                type="submit"
                color="primary"
              >
                {t("Save")}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};
