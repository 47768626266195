import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { partial } from "lodash-es";
import { FC, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../../../utils/hooks/use-dialog";
import { ChannelCard, ChannelProps } from "../../../channels/components/channel-card";
import { ChannelName } from "../../../channels/types";
import { prettyChannelName } from "../../../channels/utils";
import { MomentFormMoment } from "../../types/moment-form-moment";
import { useHandleReset } from "./hooks/useHandleReset";
import { useSelectedChannel } from "./hooks/useSelectedChannel";

export const CHAT_CHANNELS: (ChannelName | undefined)[] = ["slack", "teams"];
export const EMAIL_CHANNELS: (ChannelName | undefined)[] = ["personal_email", "work_email"];
export const TEXT_CHANNELS: (ChannelName | undefined)[] = ["twilio"];

export const isEmailChannel = (channel?: ChannelName): channel is "personal_email" | "work_email" =>
  EMAIL_CHANNELS.includes(channel);

export const isChatChannel = (channel?: ChannelName): channel is "slack" | "teams" =>
  CHAT_CHANNELS.includes(channel);

export const isTextChannel = (channel?: ChannelName): channel is "twilio" =>
  TEXT_CHANNELS.includes(channel);

export const Channel: FC<{
  channels: ChannelProps[];
  disabled: boolean;
  newsletter?: boolean;
}> = ({ channels, disabled, newsletter }) => {
  const { t } = useTranslation();
  const { handleOpen, handleClose, ...confirmModal } = useDialog<ChannelName>();

  const availableChannels = useMemo(
    () => channels.filter((c) => (newsletter ? isEmailChannel(c.name) : true)),
    [channels, newsletter],
  );

  const { reset } = useFormContext<MomentFormMoment>();
  const selectedChannel = useSelectedChannel();

  const channel = prettyChannelName(selectedChannel);

  const handleReset = useHandleReset(newsletter);

  const doSwitch = useCallback(
    async (name: ChannelName) => {
      // If no channel is currently selected, just reset with the new channel
      if (!selectedChannel) {
        await handleReset(name);
        return;
      }

      // If switching to the same channel, do nothing
      if (name === selectedChannel) return;

      // Check if switching between channels of the same type
      const isSameChannelType = [
        [CHAT_CHANNELS, CHAT_CHANNELS],
        [EMAIL_CHANNELS, EMAIL_CHANNELS],
        [TEXT_CHANNELS, TEXT_CHANNELS],
      ].some(
        ([fromChannels, toChannels]) =>
          fromChannels.includes(selectedChannel) && toChannels.includes(name),
      );

      if (isSameChannelType) {
        // For same channel type, reset form with new channel
        handleClose();
        reset((values) => ({ ...values, channel: name, additional_recipients: [] }));
      } else {
        // For different channel types, show confirmation modal
        handleOpen(name);
      }
    },
    [selectedChannel, handleOpen, handleClose, handleReset, reset],
  );

  const handleChannelChange = useCallback(
    async (name: ChannelName | null): Promise<void> => {
      await handleReset(name);
      handleClose();
    },
    [handleReset, handleClose],
  );

  return (
    <>
      {availableChannels.map(({ iconSmall, buttonText, name }, key) => (
        <Box key={key} sx={{ mr: 1 }}>
          <ChannelCard
            buttonText={buttonText}
            onClick={partial((p) => {
              void doSwitch(p).catch(console.error);
            }, name)}
            name={name}
            disabled={disabled}
            selected={name === selectedChannel}
            iconSmall={iconSmall}
          />
        </Box>
      ))}

      <Dialog
        open={confirmModal.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("Confirm")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmModal.data &&
              `You are about to change the content type of this Moment from '${channel}' to '${prettyChannelName(
                confirmModal.data,
              )}'. You will lose any existing ${channel} content if you go ahead with
                  this. Are you sure you wish to continue?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-analytics-id="moment-form-channel-select-warning-dialog-cancel"
            onClick={handleClose}
          >
            {t("Cancel")}
          </Button>
          <Button
            data-analytics-id="moment-form-channel-select-warning-dialog-confirm"
            type="submit"
            variant="outlined"
            onClick={() => void handleChannelChange(confirmModal.data || null).catch(console.error)}
          >
            {t("Yes, I'm sure")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
