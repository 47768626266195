import OpenInNew from "@mui/icons-material/OpenInNew";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DialogController } from "../../../../utils/hooks/use-dialog";
import { UUID } from "../../../../utils/uuid";

export type RelatedMoments = { title: string; moments: { id: UUID; title: string }[] };
export const RelatedMomentsDialog: FC<{
  subjectNamedInTitle: string;
  dialog: DialogController<RelatedMoments>;
}> = ({ subjectNamedInTitle, dialog }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={dialog.open} onClose={dialog.handleClose}>
      <DialogTitle>
        {t("Moments using the {{subjectNamedInTitle}}: {{title}}", {
          subjectNamedInTitle,
          title: dialog.data?.title,
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          <Trans>Click to view the Moments in more detail</Trans>
        </DialogContentText>
        {dialog.data?.moments.map((momentInfo) => (
          <DialogContentText key={momentInfo.id}>
            <Button
              data-analytics-id="related-moments-dialog-moment"
              href={`/moments/${momentInfo.id}`}
              target="_blank"
              endIcon={<OpenInNew />}
            >
              {momentInfo.title}
            </Button>
          </DialogContentText>
        ))}
      </DialogContent>
    </Dialog>
  );
};
