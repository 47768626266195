import AutoFixHigh from "@mui/icons-material/AutoFixHigh";
import FileUpload from "@mui/icons-material/FileUpload";
import { Alert, Box, Button, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import { AssetCount } from "../../modules/assets/asset-count";
import { AssetGrid } from "../../modules/assets/asset-grid";
import { AssetPreviewModal } from "../../modules/assets/asset-preview-modal";
import { ContentLibraryFilter } from "../../modules/assets/components/content-library-filter";
import { ContentTypeChips } from "../../modules/assets/content-type-chips";
import { EmptyState } from "../../modules/assets/empty-state";
import { useAssets } from "../../modules/assets/hooks/use-assets";
import { Asset } from "../../modules/assets/server/digital-asset-service";
import { UploadFileModal } from "../../modules/assets/upload-file-modal";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { useMultiformat } from "../../modules/multiformat/hooks/use-multi-format";
import { useFlag } from "../../server/optimizely";
import { useDialog } from "../../utils/hooks/use-dialog";

const ContentLibraryPage: FC = () => {
  const { t } = useTranslation();
  const [content_hosting] = useFlag("content_hosting");
  useMultiformat();
  const { data, loading, error, reload } = useAssets();
  const [filteredData, setFilteredData] = useState<null | Asset[]>(null);
  const [contentTypes, setContentTypes] = useState<string[]>([]);
  const previewModal = useDialog();
  const uploadModal = useDialog();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const formats = searchParams.has("format") && searchParams.getAll("format");
    setContentTypes(formats ? formats : []);
    if (!data) {
      setFilteredData(null);
      return;
    }

    if (!formats || formats.length === 0) {
      setFilteredData(data);
      return;
    }

    setFilteredData(data.filter((x) => formats.includes(x.content_type)));
  }, [data, searchParams]);

  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
  const handleSelectAsset = useCallback(
    (asset: Asset) => {
      setSelectedAsset(asset);
      previewModal.handleOpen();
    },
    [previewModal],
  );

  const handleUploadFile = useCallback(() => {
    uploadModal.handleOpen();
  }, [uploadModal]);

  const handleDeleted = useCallback(() => {
    previewModal.handleClose();
    reload();
  }, [previewModal, reload]);

  const handleRemoveContentTypeFilter = useCallback(
    (contentType: string) => {
      const params = new URLSearchParams(searchParams);
      const newValues = params.getAll("format").filter((c) => c !== contentType);
      params.delete("format");
      newValues.forEach((val) => params.append("format", val));
      setSearchParams(params);
    },
    [searchParams, setSearchParams],
  );

  return (
    <>
      <Page title={t("Your Content Library")}>
        <PageHeader>
          <Box display="flex" justifyContent="space-between">
            <PageHeading heading={t("Your Content Library")} />
            <Box>
              {content_hosting && (
                <Button
                  onClick={handleUploadFile}
                  variant="outlined"
                  color="primary"
                  data-analytics-id="content-library-upload-file"
                  startIcon={<FileUpload />}
                  sx={{ mr: 2 }}
                >
                  {t("Upload file")}
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                href="/create-content"
                data-analytics-id="content-library-create-content-cta"
                startIcon={<AutoFixHigh />}
              >
                {t("Create Content")}
              </Button>
            </Box>
          </Box>
        </PageHeader>

        <PageContent maxWidth="xl">
          {loading && <CircularProgress />}
          {error && (
            <Alert severity="error">
              {t("Sorry, there was a problem retrieving your content. Please try again later")}
            </Alert>
          )}
          {filteredData && (
            <Stack pt={2}>
              <Stack direction="row" justifyContent={"space-between"}>
                <AssetCount assets={filteredData} />
                <ContentLibraryFilter />
              </Stack>
              <Box sx={{ mb: 2 }}>
                <ContentTypeChips
                  onDelete={handleRemoveContentTypeFilter}
                  contentTypes={contentTypes}
                />
              </Box>
              <AssetGrid assets={filteredData} onClick={handleSelectAsset} />
            </Stack>
          )}
          {data && data.length === 0 && <EmptyState />}
        </PageContent>
      </Page>
      <AssetPreviewModal
        asset={selectedAsset}
        open={previewModal.open}
        onClose={previewModal.handleClose}
        onChange={reload}
        onDeleted={handleDeleted}
      />
      <UploadFileModal open={uploadModal.open} onClose={uploadModal.handleClose} />
    </>
  );
};

export default ContentLibraryPage;
