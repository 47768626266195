import { Alert, AlertTitle } from "@mui/material";
import Fuse from "fuse.js";
import { intersection, isEmpty } from "lodash-es";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import { queryString } from "../../filter/queryString";
import { MomentCardMoment } from "../../moment-card/types";
import { useMomentQueryFilterFunction } from "../hooks/use-client-filtered-moments";

export const FilterMoments = <T extends Omit<MomentCardMoment, "additional_recipients_json">>({
  moments,
  children,
}: {
  moments: T[];
  children: (filteredMoments: T[]) => ReactElement;
}): ReactElement => {
  const filteredMoments = useMomentQueryFilterFunction<T>()(moments);
  return children(filteredMoments);
};

// NOTE: only used by legacy library, to be removed on launch of
// library V2
export const FilterMomentsLegacy = <
  T extends Omit<MomentCardMoment, "additional_recipients_json">,
>({
  moments,
  children,
  library,
}: {
  moments: T[];
  children: (filteredMoments: T[]) => ReactElement;
  library?: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const q = searchParams?.get("q");
  const program = queryString(searchParams, "program");
  const status = queryString(searchParams, "status");
  const channel = queryString(searchParams, "channel");
  const events = queryString(searchParams, "event");

  let filteredMoments = moments;
  if (program.length !== 0) {
    filteredMoments = filteredMoments.filter((m) =>
      program.includes(m.program_id?.toString() || ""),
    );
  }
  if (status.length !== 0) {
    filteredMoments = filteredMoments.filter((m) => status.includes(m.status || ""));
  }
  if (channel.length === 1) {
    if (!isEmpty(intersection(channel, ["slack", "teams"])))
      filteredMoments = filteredMoments
        .filter((m) => m.channel === "slack" || m.channel === "teams")
        .filter((m) => library || channel.includes(m.channel as string));
    if (!isEmpty(intersection(channel, ["work_email", "personal_email"])))
      filteredMoments = filteredMoments
        .filter((m) => m.channel === "work_email" || m.channel === "personal_email")
        .filter((m) => library || channel.includes(m.channel as string));
    if (!isEmpty(intersection(channel, ["twilio"])))
      filteredMoments = filteredMoments
        .filter((m) => m.channel === "twilio")
        .filter((m) => library || channel.includes(m.channel as string));
  }

  if (events.length !== 0) {
    filteredMoments = filteredMoments.filter((m) => events.includes(m.schedule?.event_key || ""));
  }
  if (q) {
    const fuse = new Fuse(filteredMoments, { keys: ["title"] });
    filteredMoments = fuse.search(q).map((result) => result.item);
  }
  if (filteredMoments.length === 0)
    return (
      <Alert severity="info">
        <AlertTitle>{t("No moments found")}</AlertTitle>
        {t("Please change your search query and try again")}
      </Alert>
    );
  return children(filteredMoments);
};
