import { atomFamily, atomWithDefault } from "jotai/utils";
import { isEqual } from "lodash-es";
import { Orientation } from "unsplash-js";
import { brandInfoAtom, brandingAtom } from "../../brand-kit/store";
import { TemplateInfo } from "../../brand-kit/types";
import { brandInfoToCustomizations } from "../../design-huddle/brand-info-customizations/brand-info-customizations";
import {
  debugLabel,
  multiformatIconsFamily,
  multiformatPhotosFamily,
} from "../../generate/store";
import { layoutConfigurationFamily } from "./layout-configuration-family";
import { DhTemplateConfig } from "./types";

export const previewFamily = atomFamily(
  ({
    formatId,
    answers,
    imgNum,
    templateConfig: templateConfig,
    imageType,
    imageIndex,
    orientation,
    useSelectedLogo = true,
  }: {
    formatId: string;
    answers: { answer: string }[];
    imgNum?: string;
    templateConfig: DhTemplateConfig;
    imageType: string;
    imageIndex: number;
    orientation: Orientation;
    useSelectedLogo?: boolean;
  }) =>
    debugLabel(
      `previewFamily(${imgNum})`,
      atomWithDefault(async (get) => {
        if (!imgNum) return null;
        const layoutConfig = await get(
          layoutConfigurationFamily({ formatId, answer0: answers[0]?.answer ?? "", imgNum }),
        );
        if (!layoutConfig) return null;

        const brandInfo = get(brandInfoAtom);
        const branding = get(brandingAtom);

        let Photo;

        if (imageType === "Photo") {
          const photos = await get(
            multiformatPhotosFamily({
              formatId,
              description: answers[0]?.answer ?? "",
            }),
          );
          const orientedPhotos = photos ? photos.filter((p) => p.orientation === orientation) : [];
          Photo = orientedPhotos?.[imageIndex]?.url;

          if (!Photo && orientedPhotos.length > 0) {
            const randomResult = Math.floor(Math.random() * orientedPhotos.length);
            Photo = orientedPhotos[randomResult].url;
          }
        } else if (imageType === "Icon" && layoutConfig.response.search) {
          const icons = await get(
            multiformatIconsFamily({
              formatId,
              description: answers[0]?.answer ?? "",
            }),
          );
          Photo = icons?.[imageIndex]?.url;
        }

        Photo = Photo ?? layoutConfig.response.url;

        const info: TemplateInfo = {
          template_code: templateConfig.templateCode,
          page_number: parseInt(imgNum),
          stableDiffusionImage: false,
          header: layoutConfig.response.header ?? "",
          subheader: layoutConfig.response.subheader ?? "",
          tagline: layoutConfig.response.tagline ?? "",
          description: layoutConfig.response.description ?? "",
          "hashtag-1": layoutConfig.response["hashtag-1"] ?? "",
          "hashtag-2": layoutConfig.response["hashtag-2"] ?? "",
          footer: layoutConfig.response.footer ?? "",
          "logo-program-name": layoutConfig.response["logo-program-name"] ?? "",
          "logo-tagline": layoutConfig.response["logo-tagline"] ?? "",
          "certificate-title": layoutConfig.response["certificate-title"] ?? "",
          "certificate-description": layoutConfig.response["certificate-description"] ?? "",
          "question-1": layoutConfig.response["question-1"] ?? "",
          "answer-1": layoutConfig.response["answer-1"] ?? "",
          "question-2": layoutConfig.response["question-2"] ?? "",
          "answer-2": layoutConfig.response["answer-2"] ?? "",
          "question-3": layoutConfig.response["question-3"] ?? "",
          "answer-3": layoutConfig.response["answer-3"] ?? "",
          "question-4": layoutConfig.response["question-4"] ?? "",
          "answer-4": layoutConfig.response["answer-4"] ?? "",
          "question-5": layoutConfig.response["question-5"] ?? "",
          "answer-5": layoutConfig.response["answer-5"] ?? "",
          "question-6": layoutConfig.response["question-6"] ?? "",
          "answer-6": layoutConfig.response["answer-6"] ?? "",
          "question-7": layoutConfig.response["question-7"] ?? "",
          "answer-7": layoutConfig.response["answer-7"] ?? "",
          "question-8": layoutConfig.response["question-8"] ?? "",
          "answer-8": layoutConfig.response["answer-8"] ?? "",
          "question-9": layoutConfig.response["question-9"] ?? "",
          "answer-9": layoutConfig.response["answer-9"] ?? "",
          "subheader-1": layoutConfig.response["subheader-1"] ?? "",
          "subheader-2": layoutConfig.response["subheader-2"] ?? "",
          "subheader-3": layoutConfig.response["subheader-3"] ?? "",
          "subheader-4": layoutConfig.response["subheader-4"] ?? "",
          "subheader-5": layoutConfig.response["subheader-5"] ?? "",
          "subheader-6": layoutConfig.response["subheader-6"] ?? "",
          "section-1": layoutConfig.response["section-1"] ?? "",
          "section-2": layoutConfig.response["section-2"] ?? "",
          "section-3": layoutConfig.response["section-3"] ?? "",
          "section-4": layoutConfig.response["section-4"] ?? "",
          "section-5": layoutConfig.response["section-5"] ?? "",
          "section-6": layoutConfig.response["section-6"] ?? "",
          Photo,
        };

        const customizations = brandInfoToCustomizations({
          brandInfoSelected: branding,
          templateInfo: info,
          brandInfo,
          useSelectedLogo,
          imageType,
        });

        return {
          formatId,
          title: layoutConfig.response.title,
          template_code: info.template_code,
          customizations,
          page_number: info.page_number,
          ...templateConfig.dimensions,
          imageType,
          orientation,
        };
      }),
    ),
  isEqual,
);
