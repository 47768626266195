import Add from "@mui/icons-material/Add";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Edit from "@mui/icons-material/Edit";
import RestoreFromTrash from "@mui/icons-material/RestoreFromTrash";
import { Button, FormControlLabel, FormGroup, Stack, Switch, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDialog } from "../../../../utils/hooks/use-dialog";
import { UUID } from "../../../../utils/uuid";
import { CrudDataGrid } from "../../../generic/components/crud-data-grid/crud-data-grid";
import {
  RelatedMoments,
  RelatedMomentsDialog,
} from "../../../generic/components/crud-data-grid/related-moments-dialog";
import { Row } from "../../../generic/components/crud-data-grid/types";
import { SegmentWithMoments } from "../../server/supabase-segment-service";
import { ArchiveDialog } from "./delete-dialog";
import { useProcessedSegments } from "./hooks/use-processed-segments";
import { useSegmentCrudColumns } from "./hooks/use-segment-crud-columns";
import { useSegmentCrudFunctions } from "./hooks/use-segment-crud-functions";
import {
  CloneSegmentModal,
  EditSegmentModal,
  NewSegmentModal,
  UnarchiveWithNewNameModal,
} from "./segment-modal";
import { SegmentRow } from "./types";

export const SegmentCrudDataGrid: FC<{
  segments: SegmentWithMoments[];
  is_recipient_type: boolean;
  gridConfig?: {
    disableVirtualization: boolean;
  };
  reload: () => void;
}> = ({ segments, is_recipient_type, gridConfig, reload }) => {
  const { t } = useTranslation();
  const [showArchived, setShowArchived] = useState(false);
  const processedSegments = useProcessedSegments(segments, showArchived);
  const relatedMomentsDialog = useDialog<RelatedMoments>();
  const editDialog = useDialog<{ segment_id: UUID | undefined }>();
  const cloneDialog = useDialog<{ segment_id: UUID | undefined }>();
  const addDialog = useDialog();
  const confirmDeleteDialog = useDialog<{
    segment_id: UUID;
    resolve: (value: boolean | PromiseLike<boolean>) => void;
  }>();
  const confirmUnarchiveDialog = useDialog<{ segment_id: UUID; segment_name: string | null }>();
  const unarchiveWithNewNameDialog = useDialog<{ segment_id: UUID; segment_name: string | null }>();

  const { handleDelete, handleUnarchive, handleUnarchiveWithNewName, handleEdit, handleCreate } =
    useSegmentCrudFunctions({
      is_recipient_type,
      reload,
      onUnarchiveDuplicate: unarchiveWithNewNameDialog.handleOpen,
    });

  const columns = useSegmentCrudColumns({
    is_recipient_type,
    onRelatedMomentsButtonClick: relatedMomentsDialog.handleOpen,
  });

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <Button
          data-analytics-id={`segment-grid-add-${is_recipient_type ? "audience" : "segment"}`}
          color="primary"
          startIcon={<Add />}
          onClick={addDialog.handleOpen}
        >
          <Trans>Add {is_recipient_type ? "Audience" : "Segment"}</Trans>
        </Button>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={showArchived} onChange={(_, checked) => setShowArchived(checked)} />
            }
            label={t("Show archived?")}
            labelPlacement="start"
          />
        </FormGroup>
      </Stack>
      <CrudDataGrid<Row<SegmentRow>>
        onDelete={async (row) => {
          return new Promise<boolean>((resolve) => {
            // Save the arguments to resolve or reject the promise later
            confirmDeleteDialog.handleOpen({ segment_id: row.id, resolve });
          });
        }}
        initialRows={processedSegments}
        columns={columns}
        gridOverrides={
          gridConfig ? { disableVirtualization: gridConfig.disableVirtualization } : {}
        }
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
        modifyActions={(actions, row) => {
          return [
            ...(row?.deleted_at
              ? [
                  <GridActionsCellItem
                    key={row.id}
                    icon={
                      <Tooltip title={t("Unarchive")}>
                        <RestoreFromTrash />
                      </Tooltip>
                    }
                    label="Unarchive"
                    className="textPrimary"
                    onClick={() => {
                      confirmUnarchiveDialog.handleOpen({
                        segment_id: row.id,
                        segment_name: row.name,
                      });
                    }}
                    color="inherit"
                    disabled={false}
                  />,
                ]
              : actions),
            row?.id ? (
              <>
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t("Edit")}>
                      <Edit />
                    </Tooltip>
                  }
                  label="Edit"
                  className="textPrimary"
                  onClick={() => {
                    editDialog.handleOpen({ segment_id: row?.id });
                  }}
                  color="inherit"
                  disabled={false}
                />
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t("Clone")}>
                      <ContentCopy />
                    </Tooltip>
                  }
                  label="Clone"
                  className="textPrimary"
                  onClick={() => {
                    cloneDialog.handleOpen({ segment_id: row?.id });
                  }}
                  color="inherit"
                  disabled={false}
                />
              </>
            ) : (
              <></>
            ),
          ];
        }}
      />
      <RelatedMomentsDialog subjectNamedInTitle="Segment" dialog={relatedMomentsDialog} />
      <ArchiveDialog
        title={t("Archive Segment")}
        content={t("Are you sure you wish to archive this Segment?")}
        open={confirmDeleteDialog.open}
        onCancel={() => {
          confirmDeleteDialog.handleClose();
          confirmDeleteDialog.data?.resolve(false);
        }}
        onConfirm={async () => {
          confirmDeleteDialog.handleClose();
          confirmDeleteDialog.data?.resolve(
            (confirmDeleteDialog.data &&
              (await handleDelete(confirmDeleteDialog.data?.segment_id))) ??
              false,
          );
        }}
      />
      <ArchiveDialog
        title={t("Unarchive Segment")}
        content={t("Are you sure you wish to unarchive this Segment?")}
        open={confirmUnarchiveDialog.open}
        onCancel={confirmUnarchiveDialog.handleClose}
        onConfirm={async () => {
          if (confirmUnarchiveDialog.data?.segment_id)
            await handleUnarchive(
              confirmUnarchiveDialog.data?.segment_id,
              confirmUnarchiveDialog.data?.segment_name,
            );
          confirmUnarchiveDialog.handleClose();
        }}
      />
      {unarchiveWithNewNameDialog.open && (
        <UnarchiveWithNewNameModal
          open={unarchiveWithNewNameDialog.open}
          onCancel={unarchiveWithNewNameDialog.handleClose}
          segmentId={unarchiveWithNewNameDialog.data?.segment_id}
          segmentName={unarchiveWithNewNameDialog.data?.segment_name}
          onSave={async (segment) => {
            const result = await handleUnarchiveWithNewName(segment);
            if (!result) return;

            if (result?.success) addDialog.handleClose();

            return !result.success && result.errors ? result.errors : undefined;
          }}
        />
      )}
      {editDialog.open && (
        <EditSegmentModal
          open={editDialog.open}
          segmentId={editDialog.data?.segment_id}
          onCancel={() => editDialog.handleClose()}
          onSave={async (segment, onlyNameEdited) => {
            const result = await handleEdit(segment, onlyNameEdited);
            if (result.success) editDialog.handleClose();

            return !result.success && result.errors ? result.errors : undefined;
          }}
          disabled={
            (processedSegments
              .find((x) => x.id === editDialog.data?.segment_id)
              ?.moments.filter(({ finished }) => !finished).length ?? 0) > 1
          }
        />
      )}
      {cloneDialog.open && (
        <CloneSegmentModal
          open={cloneDialog.open}
          segmentId={cloneDialog.data?.segment_id}
          onCancel={() => cloneDialog.handleClose()}
          onSave={async (segment) => {
            const result = await handleCreate(segment);
            if (result.success) cloneDialog.handleClose();

            return !result.success && result.errors ? result.errors : undefined;
          }}
        />
      )}
      {addDialog.open && (
        <NewSegmentModal
          isRecipientType={is_recipient_type}
          open={addDialog.open}
          onCancel={() => addDialog.handleClose()}
          onSave={async (segment) => {
            const result = await handleCreate(segment);
            if (result.success) addDialog.handleClose();

            return !result.success && result.errors ? result.errors : undefined;
          }}
        />
      )}
    </Stack>
  );
};
