import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../../server/supabase/hooks";
import { notEmpty } from "../../../../utils/not-empty";
import { SupabaseCustomEmail } from "../../../channels/email/server/supabase-custom-email-service";
import { Program } from "../../../programs/server/supabase-program-service";
import { SupabaseSegment } from "../../../segment/server/supabase-segment-service";
import {
  Permissions,
  PersonDetails,
  SupabasePermissionsService,
} from "../../server/supabase-person-service";
import {
  PermissionsFormType,
  PermissionsLimits,
  permissionsSchema,
} from "../../types";
import { EmailFromDenyListSelect } from "./permissions-form-email-from-deny-list-select";
import { EmployeeSelect } from "./permissions-form-employee-select";
import { PermissionTypeSelect } from "./permissions-form-permission-type-select";
import { ProgramSelect } from "./permissions-form-program-select";
import { SegmentDenyListSelect } from "./permissions-form-segment-deny-list-select";

export const PermissionsForm: FC<{
  onComplete: (perms: Permissions[]) => void;
  open: boolean;
  close: () => void;
  employees: PersonDetails[];
  programs: Program[];
  segments: SupabaseSegment[];
  custom_emails: SupabaseCustomEmail[];
  capacity: PermissionsLimits;
  existing?: Permissions;
}> = ({
  onComplete,
  existing,
  open,
  employees,
  programs,
  segments,
  custom_emails,
  capacity,
  close,
}) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);

  const methods = useForm({
    defaultValues: existing?.id
      ? {
          person_ids: [existing.id],
          type: existing.super_admin ? "super_admin" : "contributor",
          ...(existing.super_admin
            ? {}
            : {
                read_only_programs: existing.read_only_program?.filter(notEmpty),
                editable_programs: existing.editable_program?.filter(notEmpty),
                segment_deny_list: existing.segment_deny_list?.filter(notEmpty),
                email_from_deny_list: existing.email_from_deny_list?.filter(notEmpty),
              }),
        }
      : permissionsSchema.cast({}),
    resolver: yupResolver(permissionsSchema),
  });

  const { reset } = methods;

  useWatch({ control: methods.control, name: ["type"] });
  const [selectedType] = methods.getValues(["type"]);

  const onSubmit = useSupabaseCallback(
    async ({ supabase }, values: PermissionsFormType): Promise<void> => {
      setSaving(true);

      const service = new SupabasePermissionsService(supabase);

      const { data, error } = await (values.type === "super_admin"
        ? service.setSuperAdmins(values.person_ids)
        : service.setPermissions(values.person_ids, {
            editable_program: values.editable_programs,
            read_only_program: values.read_only_programs,
            segment_deny_list: values.segment_deny_list,
            email_from_deny_list: values.email_from_deny_list,
          }));

      setSaving(false);

      if (error || !data) {
        toast.error(t(existing ? "Failed to update permissions" : "Failed to create permissions"));
        return;
      }

      toast.success(t(existing ? "Permissions Updated" : "Permissions created"));
      close();
      reset();
      onComplete(data);
    },
    [close, reset, onComplete, existing, t],
  );

  const atCapacity = reachedCapacity(capacity, selectedType, existing);

  return (
    <Dialog open={open} onClose={() => !saving && close()}>
      <DialogTitle>{t(existing ? "Edit User Permissions" : "Add User Permissions")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans>
            Set users permissions below. Depending on your subscription plan, you can assign
            employees as Super Admins or Contributors.
          </Trans>
        </DialogContentText>
        <FormProvider {...methods}>
          <form
            id="permissions-create-form"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <EmployeeSelect
              control={methods.control}
              employees={employees}
              existing={existing}
            />
            <PermissionTypeSelect control={methods.control} existing={existing} />
            {!atCapacity && selectedType === "contributor" && (
              <>
                <ProgramSelect type={"read_only_programs"} programs={programs} />
                <ProgramSelect type={"editable_programs"} programs={programs} />
                <SegmentDenyListSelect segments={segments} />
                <EmailFromDenyListSelect custom_emails={custom_emails} />
              </>
            )}
          </form>
        </FormProvider>
        {atCapacity && (
          <Alert severity="info" sx={{ mt: 2 }}>
            <Trans>
              Your quota for this permission type has been reached. Contact your Customer Success
              representative for more information.
            </Trans>
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          data-analytics-id={`permissions-form-dialog-${existing ? "update" : "create"}`}
          disabled={atCapacity || saving}
          type="submit"
          form="permissions-create-form"
        >
          {saving ? <CircularProgress size={20} /> : t(existing ? "Update" : "Add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const reachedCapacity = (
  capacity: PermissionsLimits,
  type: PermissionsFormType["type"],
  existing?: Permissions,
): boolean => {
  const isContributorLimit = type === "contributor" && capacity.contributors === 0;
  const isSuperAdminLimit = type === "super_admin" && capacity.super_admins === 0;

  if (!existing) return isContributorLimit || isSuperAdminLimit;

  const existingType = existing.super_admin ? "super_admin" : "contributor";

  return type === existingType ? false : isContributorLimit || isSuperAdminLimit;
};
