import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import NotInterested from "@mui/icons-material/NotInterested";
import {
    Box,
    BoxProps,
    CircularProgress,
    IconButton,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";
import { useAtom } from "jotai";
import { FC, PropsWithChildren, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router";
import { useFlag } from "../../server/optimizely";
import { UUID, uuidv4 } from "../../utils/uuid";
import {
    SequenceBarMoment as SequenceBarMomentType,
    sequenceMomentsAtom,
} from "../layout/components/store";
import { SequenceMoment } from "../moment/server/supabase-moment-service";
import { SequenceBar } from "./sequence-bar";
import { SequenceBarMoment } from "./sequence-bar-moments-card";

export const MomentSequenceBar: FC<{ sequenceId: UUID } & BoxProps> = ({ sequenceId, sx = [] }) => {
  const { t } = useTranslation();
  const [sequenceMoments, setSequenceMoments] = useAtom(sequenceMomentsAtom(sequenceId));

  const navigate = useNavigate();

  const handleAddMoment = useCallback(() => {
    const id = uuidv4();
    setSequenceMoments((prev) => [
      ...(prev ?? []),
      { id, sequence_id: sequenceId, status: "draft", barStatus: "new" } as SequenceBarMomentType,
    ]);
    void navigate(`/moments/${id}?sequence_id=${sequenceId}`);
  }, [navigate, sequenceId, setSequenceMoments]);

  const [, { layout }, ready] = useFlag("sequences");

  return (
    <SequenceBar
      sx={[
        (theme) => ({
          borderRight: `1px solid ${theme.palette.divider}`,
          "--sb-thumb-width": "80px",
          bgcolor: "background.paper",
        }),

        ...(sx instanceof Array ? sx : [sx]),
      ]}
    >
      <Box display="flex" alignItems={"center"} flexGrow={1}>
        <Box sx={{ fontSize: "1.2rem", fontWeight: 500 }} flexGrow={1}>
          {t("Moments")}
        </Box>
        <Tooltip title={t("Add New Moment")} placement="right">
          <IconButton
            data-analytics-id="sequence-sidebar-add-moment"
            size="large"
            onClick={handleAddMoment}
          >
            <AddCircleOutline />
          </IconButton>
        </Tooltip>
      </Box>
      {!ready ? (
        <SequenceLoading />
      ) : sequenceMoments && sequenceMoments.length > 0 ? (
        sequenceMoments.map((moment) => {
          return layout === "app_v2" ? (
            <SequenceBarMoment momentId={moment.id} />
          ) : (
            <TemplateThumbnailLink key={moment.id} moment={moment}>
              <TemplateThumbnail key={moment.id} moment={moment} />
            </TemplateThumbnailLink>
          );
        })
      ) : sequenceMoments && sequenceMoments.length === 0 ? (
        <NoMoments />
      ) : null}
    </SequenceBar>
  );
};

const SequenceBarMessage = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  gap: theme.spacing(2),
}));

const SequenceLoading: FC = () => {
  return (
    <SequenceBarMessage>
      <CircularProgress />
    </SequenceBarMessage>
  );
};

const NoMoments: FC = () => {
  const { t } = useTranslation();
  return (
    <SequenceBarMessage>
      <NotInterested color="disabled" />
      <Typography variant="body2" color="text.secondary">
        {t("There are no moments in this sequence")}
      </Typography>
    </SequenceBarMessage>
  );
};

const TemplateThumbnailLink: FC<{ moment: SequenceMoment } & PropsWithChildren> = ({
  moment,
  children,
}) => {
  return (
    <NavLink to={`/moments/${moment.id}`}>
      {({ isActive }) => (
        <Box
          sx={[
            (theme) => ({
              border: `2px solid transparent`,
              ":hover": {
                border: `2px solid ${theme.palette.primary.light}`,
              },
              ...(isActive && {
                ":hover": {
                  border: `2px solid ${theme.palette.primary.main}`,
                },
                border: `2px solid ${theme.palette.primary.main}`,
              }),
              borderRadius: "8px",
              overflow: "hidden",
              display: "flex",
            }),
          ]}
        >
          {children}
        </Box>
      )}
    </NavLink>
  );
};

const TemplateThumbnail: FC<{ moment: SequenceMoment }> = ({ moment }) => {
  return moment.cover_image_path ? (
    <img
      src={moment.cover_image_path}
      alt={moment.title ?? ""}
      style={{
        width: "var(--thumbnail-width)",
        height: "var(--thumbnail-width)",
        objectFit: "cover",
      }}
    />
  ) : (
    <div
      style={{
        width: "var(--thumbnail-width)",
        height: "var(--thumbnail-width)",
        borderRadius: "4px",
        backgroundColor: "gray",
      }}
    />
  );
};
