import { useCallback } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../../../server/supabase/hooks";
import { toISO8601 } from "../../../../../utils/iso8601";
import { UUID } from "../../../../../utils/uuid";
import { useSegmentService } from "../../../hooks/use-segment-service";
import { UniqueSegmentNameError } from "../../../server/segment-state-service";
import { SupabaseSegmentService } from "../../../server/supabase-segment-service";
import { Segment } from "../../../types";

type Result = { success: true } | { success: false; errors: Record<string, string> | null };

export function useSegmentCrudFunctions({
  reload,
  onUnarchiveDuplicate,
  is_recipient_type,
}: {
  is_recipient_type: boolean;
  reload: () => void;
  onUnarchiveDuplicate: (data: { segment_id: UUID; segment_name: string | null }) => void;
}): {
  handleDelete: (id: UUID) => Promise<boolean | undefined>;
  handleUnarchive: (id: UUID, name: string | null) => Promise<boolean | undefined>;
  handleUnarchiveWithNewName: (segment: Segment | null) => Promise<Result | undefined>;
  handleEdit: (segment: Segment | null, onlyNameEdited: boolean) => Promise<Result>;
  handleCreate: (segment: Segment | null) => Promise<Result>;
} {
  const { t } = useTranslation();
  const saveSegment = useSegmentService();

  const handleDelete = useSupabaseCallback(
    async ({ supabase }, segment_id: UUID): Promise<boolean> => {
      const { error } = await new SupabaseSegmentService(supabase).archive(segment_id);

      if (error) {
        toast.error(t("An error occurred while archiving the Segment"));
        return false;
      } else {
        toast.success(t("Segment archived successfully"));
        reload();
        return true;
      }
    },
    [t, reload],
  );

  const handleUnarchive = useSupabaseCallback(
    async ({ supabase }, segment_id: UUID, segment_name: string | null): Promise<boolean> => {
      const { error } = await new SupabaseSegmentService(supabase).unarchive(segment_id);

      if (error) {
        error.message.indexOf("duplicate") !== -1
          ? onUnarchiveDuplicate({ segment_id, segment_name })
          : toast.error(t("An error occurred while unarchiving the Segment"));
        return false;
      } else {
        toast.success(t("Segment unarchived successfully"));
        reload();
        return true;
      }
    },
    [t, onUnarchiveDuplicate, reload],
  );

  const handleUnarchiveWithNewName = useSupabaseCallback(
    async ({ supabase }, segment: Segment | null): Promise<Result> => {
      if (!segment?.name) return { success: false, errors: null };

      const { error } = await new SupabaseSegmentService(supabase).unarchiveWithNewName(
        segment.id,
        segment.name,
      );

      if (error) {
        return { success: false, errors: { "segment.name": error.message } };
      } else {
        toast.success(t("Segment unarchived successfully"));
        reload();
        return { success: true };
      }
    },
    [t, reload],
  );

  const updateName = useSupabaseCallback(
    async ({ supabase }, segment_id: UUID, name: string): Promise<Result> => {
      const resp = await new SupabaseSegmentService(supabase).update(segment_id, { name });

      if (resp.error) {
        return { success: false, errors: { "segment.name": "Unable to update name" } };
      }

      return { success: true };
    },
    [],
  );

  const handleEdit = useCallback(
    async (segment: Segment | null, onlyNameEdited: boolean): Promise<Result> => {
      if (!segment) return { success: false, errors: null };

      if (onlyNameEdited) {
        const result = await updateName(segment.id, segment.name ?? "");
        reload();
        return result ?? { success: false, errors: null };
      }

      try {
        await saveSegment({ ...segment, is_recipient_type });
      } catch (e) {
        if (e instanceof UniqueSegmentNameError) {
          return {
            success: false,
            errors: {
              "segment.name": t("Segment name must be unique"),
            },
          };
        }

        console.error("Error saving Segment", e);
        toast.error(t("Error saving Segment"));
        return { success: false, errors: null };
      }

      toast.success(t("Segment saved successfully"));
      reload();
      return { success: true };
    },
    [saveSegment, t, is_recipient_type, reload, updateName],
  );

  const handleCreate = useCallback(
    async (segment: Segment | null): Promise<Result> => {
      if (!segment) return { success: false, errors: null };
      const createdAt = toISO8601(new Date());

      try {
        await saveSegment({
          ...segment,
          is_recipient_type,
          created_at: createdAt,
          is_default: false,
        });
      } catch (e) {
        if (e instanceof UniqueSegmentNameError) {
          return {
            success: false,
            errors: {
              "segment.name": t("Segment name must be unique"),
            },
          };
        }

        console.error("Error creating Segment", e);
        toast.error(t("Error creating Segment"));
        return { success: false, errors: null };
      }

      toast.success(t("Segment created successfully"));
      reload();
      return { success: true };
    },
    [saveSegment, t, reload, is_recipient_type],
  );

  return { handleDelete, handleUnarchive, handleUnarchiveWithNewName, handleEdit, handleCreate };
}
