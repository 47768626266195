import SearchOutlined from "@mui/icons-material/SearchOutlined";
import { Box, BoxProps, Popover, ToggleButton } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextFieldWithAutoFocus } from "./text-field-with-autofocus";

export type TitleFilterProps = Omit<BoxProps, "onChange"> & {
  onChange: (value: string) => void;
};
export const TitleFilter: FC<TitleFilterProps> = ({ onChange, ...props }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Box {...props}>
      <ToggleButton
        data-analytics-id="tabbed-programs-search-button"
        value="search"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        title={t("Search")}
        selected={!!value}
        color={value ? "primary" : undefined}
      >
        <SearchOutlined />
      </ToggleButton>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{
          sx: { border: "none", paddingTop: 1 },
        }}
      >
        <TextFieldWithAutoFocus value={value} label={t("Search")} onChange={handleChange} />
      </Popover>
    </Box>
  );
};
