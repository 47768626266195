import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { Box, BoxProps, Chip } from "@mui/material";
import { useAtomValue } from "jotai";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { toSlug } from "../../utils/create-slug";
import { useAnalytics } from "../analytics/analytics";
import { libraryAtom } from "./library/store";

interface DiscoverProgramsProps extends BoxProps {
  initialState?: "open" | "closed";
}

export const DiscoverPrograms: FC<DiscoverProgramsProps> = ({
  initialState = "closed",
  ...boxProps
}) => {
  const { t } = useTranslation();

  const library = useAtomValue(libraryAtom);

  const [showAll, setShowAll] = useState(initialState === "open");
  const [canShowLess, setCanShowLess] = useState<boolean>(); // used to prevent hiding the last chip
  const [hasOverflow, setHasOverflow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { gaEvent } = useAnalytics();
  // if showAll is true, and hasOverflow was true, then we can show less
  useEffect(() => {
    if (hasOverflow) {
      setCanShowLess(true);
    }
  }, [hasOverflow]);

  useEffect(() => {
    if (ref.current === null) return;

    library.programs.length > 0 &&
      setHasOverflow(
        !showAll &&
          ref.current.offsetWidth !== 0 &&
          ref.current.scrollWidth > ref.current.offsetWidth,
      );
  }, [library, showAll]);

  useEffect((): void => {
    if (!ref.current) return;
    if (library.programs.length > 0) {
      const chips = Array.from(ref.current.children) as HTMLElement[];

      if (hasOverflow) {
        chips.reverse().forEach((chip) => {
          if (!ref.current) return;
          if (chip.className.includes("program-chip-more")) return;
          if (ref.current.offsetWidth < ref.current.scrollWidth) {
            chip.style.display = "none";
          }
        });
      } else {
        chips.forEach((chip) => {
          chip.style.display = "inline-flex";
        });
      }
    }
  }, [hasOverflow, library]);

  return (
    <Box role="navigation" title="Programs" display="flex" gap={1} {...boxProps}>
      <Box
        display="flex"
        flexWrap={showAll ? "wrap" : "nowrap"}
        overflow="hidden"
        ref={ref}
        gap={1}
      >
        {library.programs
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((program) => (
            <Chip
              variant="outlined"
              key={program.slug}
              label={program.name}
              component={Link}
              to={`/discover/${program.slug}`}
              onClick={() => {
                gaEvent("discover_programs", { action: "click", value: program.slug });
              }}
              clickable
              data-testid={`chip-${toSlug(program.name)}`}
            />
          ))}
        {hasOverflow && !showAll && (
          <Chip
            key="more"
            variant="outlined"
            label={t("More")}
            icon={<KeyboardArrowDown />}
            onClick={() => setShowAll(true)}
            className="program-chip-more"
          />
        )}

        {showAll && canShowLess && (
          <Chip
            key="more"
            variant="outlined"
            label={t("Less")}
            icon={<KeyboardArrowUp />}
            onClick={() => setShowAll(false)}
            className="program-chip-less"
          />
        )}
      </Box>
    </Box>
  );
};
