import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { alpha, IconButton, Popover, SxProps, Theme, Typography } from "@mui/material";
import { FC, ReactNode, useRef } from "react";
import { usePopover } from "../../../utils/hooks/use-popover";
import { uuidv4 } from "../../../utils/uuid";

export const CeHelpButton: FC<{
  content: ReactNode;
  buttonSx?: SxProps<Theme>;
  variant?: "tooltip" | "popover";
}> = ({ content, variant = "popover" }) => {
  const popover = usePopover<HTMLButtonElement>();
  const ref = useRef(uuidv4());

  return (
    <>
      <IconButton
        data-analytics-id="ce-help-button"
        onMouseEnter={popover.handleOpen}
        onMouseLeave={popover.handleClose}
        ref={popover.anchorRef}
        aria-owns={ref.current}
        aria-haspopup="true"
      >
        <InfoOutlined fontSize="small" color="primary" />
      </IconButton>
      <Popover
        id={ref.current}
        open={popover.open}
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        slotProps={{
          paper: {
            onMouseEnter: popover.handleOpen,
            onMouseLeave: popover.handleClose,
            sx: (theme) => ({
              pointerEvents: "auto",
              maxHeight: "75vh",
              overflow: "scroll",
              ...(variant === "tooltip" && {
                backgroundColor: alpha(theme.palette.grey[700], 0.92),
                color: theme.palette.common.white,
                borderWidth: 0,
              }),
            }),
          },
        }}
        sx={{
          pointerEvents: "none",
        }}
      >
        {typeof content === "string" ? (
          <Typography variant="body2" sx={{ p: 2, maxWidth: "30em" }}>
            {content}
          </Typography>
        ) : (
          content
        )}
      </Popover>
    </>
  );
};
