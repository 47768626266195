import { yupResolver } from "@hookform/resolvers/yup";
import Close from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useCallback, useEffect, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { UUID } from "../../utils/uuid";
import yup from "../../utils/yup";
import { useBrandTones } from "../brand-kit/hooks/use-brand-tones";
import { GenerateToneSelector } from "../generate/generate-tone-selector";
import { Recipe } from "../self-driving/types";


export const RecipeUserInputModal: FC<
  { recipe: Recipe | null; sequenceId?: UUID | null } & Omit<DialogProps, "children">
> = ({ recipe, sequenceId, ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit: SubmitHandler<{ answers: string[]; tone?: string }> = useCallback(
    ({ answers, tone }) => {
      const q = new URLSearchParams();
      tone && q.append("brand_tone", tone);
      answers.map((a) => q.append("answer", a));
      sequenceId && q.append("sequence_id", sequenceId);
      void navigate(`/recipes/${recipe?.id}?${q.toString()}`);
    },
    [recipe?.id, navigate, sequenceId],
  );

  const schema = yup.object({
    answers: yup.array(yup.string().required()).required(),
    tone: yup.string().uuid(),
  });

  const { data: brandTones, loading } = useBrandTones();

  const tone = useMemo(() => brandTones?.find((b) => b.is_default)?.id, [brandTones]);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      answers: [],
      tone,
    },
  });

  useEffect(() => {
    if (tone) {
      methods.reset({ answers: [], tone });
    }
  }, [methods, tone]);

  if (loading || brandTones === null) return null;

  return (
    <Dialog {...props}>
      <DialogTitle
        sx={{
          height: "200px",
          width: "100%",
          backgroundImage: `url(${recipe?.thumbnail})`,
          backgroundSize: "cover",
        }}
      ></DialogTitle>
      <DialogTitle>{recipe?.name}</DialogTitle>
      <IconButton
        data-analytics-id="recipe-user-dialog-modal-close"
        onClick={(e) => {
          methods.reset();
          props.onClose?.(e, "escapeKeyDown");
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
          borderRadius: "50%",
          backgroundColor: "rgba(0,0,0,0.3)",
          ":hover": {
            backgroundColor: "rgba(0,0,0,0.4)",
          },
        }}
        aria-label={t("Close")}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ minWidth: "500px" }}>
        <FormProvider {...methods}>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <Stack gap={4}>
              {recipe?.user_input.map((question, i) => (
                <Stack gap={2} key={question.question}>
                  <Typography>{question?.question}</Typography>

                  <TextField
                    multiline
                    minRows={1}
                    {...methods.register(`answers.${i}`)}
                    placeholder={`Example: ${question?.example}`}
                    fullWidth
                    error={!!methods.formState.errors.answers?.[i]}
                  />
                </Stack>
              ))}

              <Box display="flex" gap={4}>
                <GenerateToneSelector loading={loading} brandTones={brandTones} />
                <Button
                  data-analytics-id="recipe-user-dialog-modal-generate"
                  type="submit"
                  variant="contained"
                >
                  {t("Generate")}
                </Button>
              </Box>
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};
