import Info from "@mui/icons-material/Info";
import { Link } from "@mui/material";
import { FC, useMemo } from "react";
import { Trans } from "react-i18next";
import { HoverChip } from "../generic/components/hover-chip";

export const TemplateImageAttribution: FC<{ attributionData: { name: string; url: string } }> = ({
  attributionData,
}) => {
  const params = useMemo(() => {
    const urlParams = new URLSearchParams();
    urlParams.set("utm_source", "ChangeEngine");
    urlParams.set("utm_medium", "referral");
    return urlParams.toString();
  }, []);

  return (
    <HoverChip
      size="small"
      sx={{ position: "absolute", bottom: 5, left: 5 }}
      defaultProps={{ icon: <Info /> }}
      hoverProps={{
        icon: <Info />,
        label: (
          <Trans i18nKey="unsplashAttribution" values={{ name: attributionData.name }}>
            <Link href={`${attributionData.url}?${params}`} target="_blank">
              placeholder
            </Link>
            <Link href={`https://www.unsplash.com?${params}`} target="_blank">
              placeholder
            </Link>
          </Trans>
        ),
      }}
    />
  );
};
