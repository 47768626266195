import Sms from "@mui/icons-material/Sms";
import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import {
  PersonalEmailIcon,
  PersonalEmailIconWhite,
  WorkEmailIcon,
  WorkEmailIconWhite,
} from "../../styles/icons/email";
import { SlackIcon } from "../../styles/icons/slack";
import { SlackWhiteIcon } from "../../styles/icons/slack-white";
import { TeamsIcon } from "../../styles/icons/teams";
import { TeamsWhiteIcon } from "../../styles/icons/teams-white";
import { ChannelName } from "../channels/types";
import { useAccount } from "../generic/hooks/use-account";

export type ChannelToggleProps = {
  value?: ChannelName;
  onChange?: (channelName: ChannelName) => void;
};

export const ChannelToggle: FC<
  ChannelToggleProps & Omit<ToggleButtonGroupProps, "value" | "onChange">
> = ({ value: channelName, onChange, ...props }) => {
  const { t } = useTranslation();
  const { account } = useAccount();

  const value = channelName || "";

  const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: ChannelName): void => {
    onChange?.(newValue);
    event.preventDefault();
  };

  return (
    <ToggleButtonGroup
      value={value}
      onChange={handleChange}
      exclusive
      color="primary"
      aria-label="Channel filter"
      {...props}
    >
      {account?.enable_slack && (
        <ToggleButton
          data-analytics-id="discover-sequence-channel-toggle-slack"
          value="slack"
          title={t("Slack")}
        >
          {(value && value === "slack") || !value ? <SlackIcon /> : <SlackWhiteIcon />}
        </ToggleButton>
      )}
      {account?.enable_teams && (
        <ToggleButton
          data-analytics-id="discover-sequence-channel-toggle-teams"
          value="teams"
          title={t("Teams")}
        >
          {(value && value === "teams") || !value ? <TeamsIcon /> : <TeamsWhiteIcon />}
        </ToggleButton>
      )}
      <ToggleButton
        data-analytics-id="discover-sequence-channel-toggle-work-email"
        value="work_email"
        title={t("Work email")}
      >
        {(value && value === "work_email") || !value ? <WorkEmailIcon /> : <WorkEmailIconWhite />}
      </ToggleButton>
      <ToggleButton
        data-analytics-id="discover-sequence-channel-toggle-personal-email"
        value="personal_email"
        title={t("Personal email")}
      >
        {(value && value === "personal_email") || !value ? (
          <PersonalEmailIcon />
        ) : (
          <PersonalEmailIconWhite />
        )}
      </ToggleButton>
      {account?.enable_twilio && (
        <ToggleButton
          data-analytics-id="discover-sequence-channel-toggle-sms"
          value="twilio"
          title="SMS"
        >
          {(value && value === "twilio") || !value ? <Sms color="primary" /> : <Sms />}
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};
