import ListAlt from "@mui/icons-material/ListAlt";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { isEmpty } from "lodash-es";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { statusToPill } from "../../../../moment-card/utils";
import { SlackAliasWithDefault } from "../../../../slack/server/slack-supabase-service";

export const ViewMomentsAction: FC<{ alias: SlackAliasWithDefault }> = ({ alias }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const linked: SlackAliasWithDefault["linked_moments"] = alias.linked_moments || [];

  return (
    <>
      <Tooltip
        title={t(
          !isEmpty(linked)
            ? "View Moments associated with this alias"
            : "No Moments associated with this alias",
        )}
        placement="top"
        sx={{ cursor: "pointer" }}
      >
        <ListAlt
          fontSize="small"
          color={!isEmpty(linked) ? "info" : "disabled"}
          onClick={!isEmpty(linked) ? () => setOpen(true) : undefined}
        />
      </Tooltip>
      {!isEmpty(linked) && (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>{t("Moments")}</DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }}>
                <TableHead>
                  <TableRow>
                    <TableCell width="65%">{t("Moment")}</TableCell>
                    <TableCell width="35%">{t("Status")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {linked.map((m) => (
                    <TableRow key={m.id}>
                      <TableCell>
                        <Link href={`/moments/${m.id}`}>{m.title}</Link>
                      </TableCell>
                      <TableCell>{statusToPill(m.status)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
